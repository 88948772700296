import { ListPageContainer } from '@/components';
import { categoryActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Category"
            detailUrl ={paths.categoryDetail}
            createUrl={paths.categoryCreate}
            breadcrumbs={[
                { breadcrumbName: 'Category' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                { title: 'Name', dataIndex: 'name' },
                { title: 'Slug', dataIndex: 'slug' }
            ]}
            searchFields={[
                { key: 'name', searchPlaceholder: 'Name' }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: false
            }}
            getListAction={categoryActions.getList}
            deleteAction={categoryActions.delete}
        />

    )
}

export default ListPage;