import { createAction } from 'redux-actions';

export const actionTypes = {
    CREATE: 'attributeValue/CREATE',
    UPDATE: 'attributeValue/UPDATE',
    DELETE: 'attributeValue/DELETE',
}

export const actions = {
    create: createAction(actionTypes.CREATE),
    update: createAction(actionTypes.UPDATE),
    delete: createAction(actionTypes.DELETE),
}

