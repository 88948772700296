import { ListPageContainer } from '@/components';
import { attributeActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Attribute"
            detailUrl ={paths.attributeDetail}
            createUrl={paths.attributeCreate}
            breadcrumbs={[
                { breadcrumbName: 'Attribute' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                { title: 'Name', dataIndex: 'name' },
                { title: 'Slug', dataIndex: 'slug' }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: false
            }}
            getListAction={attributeActions.getList}
            deleteAction={attributeActions.delete}
        >
            
        </ListPageContainer>

    )
}

export default ListPage;