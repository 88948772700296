import { useMemo } from 'react';
import { Form, Row, Col } from 'antd';

import apiConfig from '@/constants/apiConfig';

import {
    TextField,
    FieldSet,
    BooleanField,
    TextEditorField,
    AsyncDropdownField,
    UploadImageImmediatelyField,
} from '@/components';

const ProductForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const initForm = {}

    const imageUploaded = useMemo(() => {
        return (detailData?.images || []).map(el => ({ ...el, url: el.image, uid: el.id }));
    }, [ detailData ])

    return (
        <Form
            id={formId}
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            initialValues={isCreating ? initForm : detailData}
        >
            <FieldSet title="General Infomation">
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            fieldName="name"
                            label="Name"
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <BooleanField
                            fieldName="isPublished"
                            label="Published"
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            fieldName="description"
                            label="Description"
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <TextEditorField
                            fieldName="longDescription"
                            label="Long Description"
                        />
                    </Col>
                </Row>
            </FieldSet>
            <FieldSet title="Product Type">
                <Row gutter={16}>
                    <Col span={12}>
                        <AsyncDropdownField
                            fieldName="productTypeId"
                            label="Product Type"
                            apiConfig={apiConfig.productType.getList}
                            mappingData={response => response?.data || []}
                            optionValue="id"
                            optionLabel="name"
                            required
                            disabled={!isCreating}
                        />
                    </Col>
                    <Col span={12}>
                        <AsyncDropdownField
                            fieldName="categoryId"
                            label="Category"
                            apiConfig={apiConfig.category.getList}
                            mappingData={response => response?.data?.content || []}
                            optionValue="id"
                            optionLabel="name"
                            required
                        />
                    </Col>
                </Row>
            </FieldSet>
            <FieldSet title="SEO">
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            fieldName="slug"
                            label="Slug"
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            fieldName="seoTitle"
                            label="SEO Title"
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            fieldName="seoDescription"
                            label="SEO description"
                        />
                    </Col>
                </Row>
            </FieldSet>
            {!isCreating && (
                <FieldSet title="Product Images">
                    <UploadImageImmediatelyField
                        fieldName="image"
                        // label="Image"
                        directory="categories"
                        uploadApiConfig={apiConfig.product.uploadImage}
                        deleteApiConfig={apiConfig.product.deleteImage}
                        objectId={detailData?.id}
                        defaultValue={imageUploaded}
                    />
                </FieldSet>
            )}
        </Form>
    );
};

export default ProductForm;