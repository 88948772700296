import { put, takeLatest } from 'redux-saga/effects';

import { accountActions, accountActionTypes } from '@/redux/actions';
import { setLocalStorage, removeItem } from '@/utils/localStorage';
import { storageKeys } from '@/constants';
import apiConfig from '@/constants/apiConfig';
import paths from '@/constants/paths';
import { processCallbackAction, processLoadingAction } from '../helper';

const {
    LOGIN,
    LOGOUT,
    UPDATE_TOKEN,
    GET_PROFILE
} = accountActionTypes;

const login = (action) => {
    return processCallbackAction(apiConfig.account.login, action);
}

const getProfile = (action) => {
    return processLoadingAction(apiConfig.account.getProfile, action);
}

const logout = () => {
    try {
        removeItem(storageKeys.ACCESS_TOKEN);
        removeItem(storageKeys.REFRESH_TOKEN);
        window.location.replace(paths.login);
    } catch (error) {
        console.log(error);
    }
}

function* updateToken(action) {
    const { accessToken, refreshToken } = action.payload || {};
    if (accessToken && refreshToken) {
        setLocalStorage(storageKeys.ACCESS_TOKEN, accessToken);
        setLocalStorage(storageKeys.REFRESH_TOKEN, refreshToken);
    }
    else {
        yield put(accountActions.logout());
    }
}

const accountSagas = [
    takeLatest(LOGIN, login),
    takeLatest(LOGOUT, logout),
    takeLatest(UPDATE_TOKEN, updateToken),
    takeLatest(GET_PROFILE, getProfile),
];

export default accountSagas;