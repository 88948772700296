import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import CategoryForm from './CategoryForm';

import paths from '@/constants/paths';
import { categoryActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Categories', path: paths.categoryList },
            { breadcrumbName: isCreating ? 'Create' : data?.name || '' }
        ];
    }
    return (
        <SavePageContainer
            form={CategoryForm}
            isCreating={!id}
            isUpdateRefresh
            objectName="category"
            listUrl={paths.categoryList}
            detailUrl={paths.categoryDetail}
            createAction={categoryActions.create}
            updateAction={categoryActions.update}
            getDetailAction={categoryActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />
    )
}

export default SavePage;