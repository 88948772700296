import React from 'react';
import {
    UsergroupAddOutlined,
    SettingOutlined,
    FolderOutlined,
    SwapOutlined,
    FileOutlined
} from '@ant-design/icons';

import paths from './paths';

const navMenuConfig = [
    {
        label: 'Order Management',
        icon: <SwapOutlined />,
        path: paths.orderList,
    },
    {
        label: 'User Mangement',
        icon: <UsergroupAddOutlined />,
        children: [
            {
                label: 'Administrator',
                path: paths.adminList
            },
        ]
    },
    {
        label: 'Content Mangement',
        icon: <FileOutlined />,
        children: [
            {
                label: 'Page',
                path: paths.pageList
            },
        ]
    },
    {
        label: 'Catalog',
        icon: <FolderOutlined />,
        children: [
            {
                label: 'Category',
                path: paths.categoryList
            },
            {
                label: 'Collection',
                path: paths.collectionList
            },
            {
                label: 'Product',
                path: paths.productList
            },
        ]
    },
    {
        label: 'Configuration',
        icon: <SettingOutlined />,
        children: [
            {
                label: 'Attribute',
                path: paths.attributeList
            },
            {
                label: 'Product Type',
                path: paths.productTypeList
            },
        ]
    },
]

export default navMenuConfig;
