import { Badge, Space } from 'antd';

import { ListPageContainer } from '@/components';
import { collectionActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Collection"
            detailUrl ={paths.collectionDetail}
            createUrl={paths.collectionCreate}
            breadcrumbs={[
                { breadcrumbName: 'Collection' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                { title: 'Name', dataIndex: 'name' },
                { title: 'Description', dataIndex: 'description' },
                {
                    title: 'Publish',
                    dataIndex: 'isPublished',
                    render: isPublished => <Space size={6}><Badge status={isPublished ? 'success' : 'processing'}/> {isPublished ? 'Published' : 'Hidden'}</Space>,
                },
            ]}
            searchFields={[
                { key: 'name', searchPlaceholder: 'Name' }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: false
            }}
            getListAction={collectionActions.getList}
            deleteAction={collectionActions.delete}
        />

    )
}

export default ListPage;