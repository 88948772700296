import { ListPageContainer, StatusBadge } from '@/components';
import { adminActions } from '@/redux/actions';
import { statusDDL } from '@/constants/masterData';
import { fieldTypes } from '@/constants';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Administrator"
            detailUrl ={paths.adminDetail}
            createUrl={paths.adminCreate}
            breadcrumbs={[
                { breadcrumbName: 'Administrators' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                { title: 'Username', dataIndex: 'username' },
                { title: 'E-mail', dataIndex: 'email' },
                { title: 'Full Name', dataIndex: 'fullName' },
                { title: 'Status', dataIndex: 'status', render: status => <StatusBadge status={status}/>},
            ]}
            searchFields={[
                { key: 'username', searchPlaceholder: 'Username' },
                { key: 'email', searchPlaceholder: 'E-mail' },
                {
                    key: 'status',
                    searchPlaceholder: 'Select Status',
                    fieldType: fieldTypes.SELECT,
                    options: statusDDL,
                }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={adminActions.getList}
            deleteAction={adminActions.delete}
        >
            
        </ListPageContainer>

    )
}

export default ListPage;