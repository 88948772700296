import React from 'react';
import { Col, Row } from 'antd';

const TextOnlyShow = ({label, value, bold}) => {
    return (
        <Row gutter={16} style={{marginBottom: '16px'}}>
            <Col span={6 } >
                <span style={{fontWeight: 'bold'}}>{label}:</span>
            </Col>
            <Col span={18} style={{fontWeight: bold ? 'bold' : 'normal'}}>
                { value }
            </Col>
        </Row>
    )
}

export default TextOnlyShow;
