export const ecomApiUrl = process.env.REACT_APP_ECOM_API;
export const DEFAULT_TABLE_ITEM_SIZE = 10;

// Datetime format
export const DATE_FORMAT_DISPLAY = 'DD-MM-YYYY';
export const DATE_FORMAT_VALUE = 'YYYY-MM-DD';
export const DATETIME_FORMAT_VALUE = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT_DISPLAY = 'DD-MM-YYYY HH:mm:ss';

export const storageKeys = {
    ACCESS_TOKEN: 'sso-access-token',
    REFRESH_TOKEN: 'sso-refresh-token',
}

export const fieldTypes = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    SELECT: 'SELECT',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    DATE: 'DATE',
    UPLOAD: 'UPLOAD',
    RADIO: 'RADIO',
    BOOLEAN: 'BOOLEAN',
    CHECKBOX: 'CHECKBOX'
}

export const commonStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    LOCK: 'LOCK'
}

export const userRoles = {
    ADMIN: 'ADMIN',
    SUPPER_ADMIN: 'SUPPER_ADMIN'
}

export const productAttributeInputTypes = {
    DROPDOWN: 'DROPDOWN',
    MULTISELECT: 'MULTISELECT',
    PLAIN_TEXT: 'PLAIN_TEXT',
    RICH_TEXT: 'RICH_TEXT'
}

export const attributeTypes = {
    ATTRIBUTE: 'attribute',
    VARIANT: 'variant'
}

export const orderStatus = {
    UNFULFILLED: 'UNFULFILLED',
    FULFILLED: 'FULFILLED',
    DELIVERY: 'DELIVERY',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
}

export const paymentStatus = {
    NOT_CHARGED: 'NOT_CHARGED',
    PENDING: 'PENDING',
    CHARGED: 'CHARGED',
    REFUNDED: 'REFUNDED',
    CANCELLED: 'CANCELLED',
}
