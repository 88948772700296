const paths = {
    login: '/login',
    adminList: '/admins',
    adminCreate: '/admins/create',
    adminDetail: '/admins/:id',
    productTypeList: '/product-types',
    productTypeCreate: '/product-types/create',
    productTypeDetail: '/product-types/:id',
    attributeList: '/attributes',
    attributeCreate: '/attributes/create',
    attributeDetail: '/attributes/:id',
    categoryList: '/categories',
    categoryCreate: '/categories/create',
    categoryDetail: '/categories/:id',
    collectionList: '/collections',
    collectionCreate: '/collections/create',
    collectionDetail: '/collections/:id',
    productList: '/products',
    productCreate: '/products/create',
    productDetail: '/products/:id',
    orderList: '/orders',
    orderDetail: '/orders/:id',
    pageList: '/pages',
    pageCreate: '/pages/create',
    pageDetail: '/pages/:id',
}

export default paths;