import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Divider, Form, Modal } from 'antd';
import { Link, useSearchParams  } from 'react-router-dom';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { PageWrapper, BaseTable, SearchForm } from '@/components';

import { DEFAULT_TABLE_ITEM_SIZE, DATE_FORMAT_VALUE, fieldTypes } from '@/constants';
import { isNumeric, cleanObject } from '@/utils';
import { useNotification } from '@/hooks';

import styles from './index.module.scss';

const { confirm } = Modal;

const ListPageContainer = ({
    objectName,
    breadcrumbs,
    columns,
    disablePagination,
    dataRowKey = 'id',
    actionBar,
    detailUrl,
    createUrl,
    searchFields,
    getListAction,
    deleteAction,
    mappingSearchParams
}) => {
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchForm] = Form.useForm();

    const dispatch = useDispatch();
    const { showErrorMessage, showSucsessMessage } = useNotification();
    
    const paginationRef = useRef({ pageSize: DEFAULT_TABLE_ITEM_SIZE });

    const prepareGetListParams = useCallback(() => {
        let page = 0;
        let filter = {}
        if(paginationRef.current.current) {
            page = paginationRef.current.current - 1;
        }
        if (mappingSearchParams) {
            filter = mappingSearchParams(searchFields);
        } else {
            searchFields?.forEach(searchField => {
                if (searchParams.has(searchField.key)) {
                    filter[searchField.key] = searchParams.get(searchField.key);
                }
            });
        }


        return {
            size: paginationRef.current.pageSize,
            page,
            ...filter
        }
    }, [searchFields, searchParams])

    const prepareColumns = () => {
        if(actionBar.isEdit || actionBar.isDelete) {
            return [
                ...columns,
                renderActionColumn()
            ]
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSearchParams({ 'page': pagination.current });
    }

    const getList = useCallback(() => {
        if(!getListAction) return;
        setLoading(true);
        dispatch(getListAction({
            params: prepareGetListParams(),
            onCompleted: response => {
                if (response?.result) {
                    if(disablePagination) {
                        setList(response?.data || []);
                    }
                    else {
                        const { content, totalElements } = response?.data || {};
                        paginationRef.current.total = totalElements || 0;
                        setList(content || []);
                    }
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        }));
    }, [getListAction, prepareGetListParams, disablePagination])

    const onSearch = (values) => {
        Object.keys(values).forEach(key => {
            if (dayjs.isDayjs(values[key])){
                values[key] = dayjs(values[key]).format(DATE_FORMAT_VALUE)
            } else {
                values[key] = values[key]?.toString()?.trim();
            }
        });

        setSearchParams(cleanObject(values));
    }

    const onResetSearch = () => {
        searchForm.resetFields();
        setSearchParams({});
    }

    const onConfirmDelete = (id) => {
        confirm({
            title: `Are you sure delete this ${objectName}?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                onDelete(id);
            },
            onCancel() {
            },
        });
    }

    const onDelete = (id) => {
        if (deleteAction) {
            dispatch(deleteAction({
                params: { id },
                onCompleted: () => {
                    showSucsessMessage(`Delete ${objectName} successful!`);
                    const isLastItem = list?.length === 1;
                    if(paginationRef.current.current > 1 && isLastItem) {
                        setSearchParams({ 'page': paginationRef.current.current - 1 });
                    }
                    else {
                        getList();
                    }
                    
                },
                onError: err => {
                    showErrorMessage(`Delete ${objectName} failed. Please try again!`)
                }
            }))
        }
    }

    useEffect(() => {
        let page = 1;
        if(searchParams.has('page') &&  isNumeric(searchParams.get('page'))) {
            page = parseInt(searchParams.get('page'));
        }
        paginationRef.current.current = page;
        if(searchFields?.length) {
            const searchFormValues = {}
            searchFields.forEach(searchField => {
                const fieldValue = searchParams.get(searchField.key);
                if(searchParams.has(searchField.key) && searchFormValues[searchField.key] !== fieldValue) {
                    if(searchField.fieldType === fieldTypes.DATE){
                        searchFormValues[searchField.key] = dayjs(fieldValue);
                        return;
                    }

                    if (searchField.mode === 'multiple' && [fieldTypes.AUTOCOMPLETE, fieldTypes.SELECT].includes(searchField.fieldType)) {
                        searchFormValues[searchField.key] = (fieldValue || '').split(',').filter(Boolean);
                        return;
                    }

                    searchFormValues[searchField.key] = fieldValue;
                }
            });

            if(Object.keys(searchFormValues).length > 0) {
                searchForm.setFieldsValue(searchFormValues);
            }
        }
        getList();
    }, [getListAction, searchParams, searchFields, searchForm, getList])

    const renderSearchForm = () => {
        if (searchFields?.length > 0)
            return <SearchForm
                searchFields={searchFields}
                onSubmit={onSearch}
                onResetForm={onResetSearch}
                form={searchForm}
            />;
        return null;
    }

    const renderActionColumn = () => {
        return {
            title: 'Action',
            width: '100px',
            align: 'center',
            render: (dataRow) => {
                const actionColumns = [];
                if (actionBar.isEdit) {
                    actionColumns.push(
                        <Link to={detailUrl.replace(':id', dataRow.id)}>
                            <Button type="link">
                                <EditOutlined />
                            </Button>
                        </Link>
                    )
                }
                if (actionBar.isDelete) {
                    actionColumns.push(
                        <Button type="link" onClick={e => {
                            e.stopPropagation();
                            onConfirmDelete(dataRow.id);
                        }}>
                            <DeleteOutlined />
                        </Button>
                    )
                }
                const actionColumnsWithDivider = [];
                actionColumns.forEach((action, index) => {
                    actionColumnsWithDivider.push(action);
                    if (index !== (actionColumns.length - 1)) {
                        actionColumnsWithDivider.push(<Divider type="vertical" />);
                    }
                })
                return (
                    <span className={styles.actionCol}>
                        {
                            actionColumnsWithDivider.map((action, index) => <span key={index}>{action}</span>)
                        }
                    </span>
                )
            }
        }
    }

    return (
        <PageWrapper
            breadcrumbs={breadcrumbs}
        >
            <div className={styles.listPage}>
                {renderSearchForm()}
                <Row justify="end" className={styles.actionBar}>
                    <Col>
                        {
                            createUrl
                                ?
                                <Link to={createUrl}>
                                    <Button type="primary">
                                        <PlusOutlined /> New {objectName}
                                    </Button>
                                </Link>
                                :
                                null
                        }
                    </Col>
                </Row>
                <BaseTable
                    rowKey={dataRowKey}
                    columns={prepareColumns()}
                    loading={loading}
                    dataSource={list}
                    pagination={disablePagination ? false : paginationRef.current}
                    onChange={handleTableChange}
                />
            </div>
        </PageWrapper>

    )
}

export default ListPageContainer;