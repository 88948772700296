import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import useFetch from '@/hooks/useFetch';

import FormItem from './FormItem';

import styles from './UploadImageField.module.scss';

const UploadImage = ({
    value,
    onChange,
    maxCount,
    defaultValue,
    objectId,
    uploadApiConfig,
    deleteApiConfig,
}) => {
    const { execute: executeUploadImage } = useFetch(uploadApiConfig);
    const { execute: executeDeleteImage } = useFetch(deleteApiConfig);

    const [fileList, setFileList] = useState([]);

    const handleChange = ({ fileList, file }) => {
        const newList = fileList.map(el => {
            if (el.uid === file?.uid) {
                return {
                    ...el,
                    ...file.response,
                    url: file.response?.data,
                }
            }

            return el;
        });
        setFileList(newList);

        if (file.status !== 'uploading') {
            onChange((!maxCount || maxCount > 1) ? newList.map(el => el.url) : (newList[0]?.url || ''));
        }
    }

    const uploadFile = ({ file, onSuccess, onError }) => {
        executeUploadImage({
            params: {
                file,
                id: objectId,
            },
            onCompleted: (result) => {
                onSuccess(result?.data);
            },
            onError,
        });
    };

    const removeFile = async (file) => {
        executeDeleteImage({
            params: {
                id: file.id,
            },
            onCompleted: () => {},
            onError: () => {},
        });

        return true;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        setFileList(defaultValue);
    }, [defaultValue]);

    return (
        <Upload
            listType="picture-card"
            onChange={handleChange}
            fileList={fileList}
            showUploadList={{
                showPreviewIcon: false
            }}
            customRequest={uploadFile}
            maxCount={maxCount}
            className={styles.uploadImage}
            accept="image/*"
            onRemove={removeFile}
        >
            {(!maxCount || fileList.length < maxCount) && uploadButton}
        </Upload>
    );
}

const UploadImageImmediatelyField = ({
    type,
    disabled,
    defaultValue,
    prefix,
    maxCount,
    objectId,
    uploadApiConfig,
    deleteApiConfig,
    ...formItemProps
}) => {


    return (
        <FormItem
            {...formItemProps}
        >
            <UploadImage
                maxCount={maxCount}
                defaultValue={defaultValue}
                objectId={objectId}
                uploadApiConfig={uploadApiConfig}
                deleteApiConfig={deleteApiConfig}
            />
        </FormItem>
    )
}

export default UploadImageImmediatelyField;
