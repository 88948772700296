import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Select } from 'antd';
import { useDispatch } from 'react-redux';


import { PageWrapper, TextOnlyShow, FieldSet, FullScreenLoading } from '@/components';
import OrderLine from './OrderLine';

import { useNotification, useFetch } from '@/hooks';
import apiConfig from '@/constants/apiConfig';
import paths from '@/constants/paths';
import { formatNumber, mappingLabelValue } from '@/utils';
import { convertUtcToLocalTimeString } from '@/utils/datetime';
import { orderStatusDDL, paymentStatusDDL } from '@/constants/masterData';
import { orderStatus } from '@/constants';
import { orderActions } from '@/redux/actions';

const DetailPage = () => {
    const { id } = useParams();
    const [orderStatusSelected, setOrderStatusSelected] = useState(null);
    const [isOrderStatusUpdating, setIsOrderStatusUpdating] = useState(false);
    const { showErrorMessage, showSucsessMessage } = useNotification();
    const disptch = useDispatch();

    const { data: order, setData: updateOrderData } = useFetch(
        {
            ...apiConfig.order.getDetail,
            path: apiConfig.order.getDetail.path.replace(':id', id)
        },
        {
            immediate: true,
            mappingData: response => response?.data || []
    });

    const getOrderStatusList = useMemo(() => {
        if(orderStatusSelected) {
            switch(orderStatusSelected) {
                case orderStatus.UNFULFILLED:
                    return orderStatusDDL.filter(os => [orderStatus.UNFULFILLED, orderStatus.FULFILLED, orderStatus.CANCELED].includes(os.value));
                case orderStatus.FULFILLED:
                    return orderStatusDDL.filter(os => [orderStatus.FULFILLED, orderStatus.DELIVERY, orderStatus.CANCELED].includes(os.value));
                case orderStatus.DELIVERY:
                    return orderStatusDDL.filter(os => [orderStatus.DELIVERY, orderStatus.COMPLETED].includes(os.value));
                default:
                    return orderStatusDDL.filter(os => os.value === orderStatusSelected);

            }
        }
        return [];
    }, [orderStatusSelected])

    const canUpdateOrderStatus = useMemo(() => {
        return order?.status && orderStatusSelected && order?.status !== orderStatusSelected;
    }, [orderStatusSelected, order])

    const updateOrderStatus = () => {
        setIsOrderStatusUpdating(true);
        disptch(orderActions.updateOrderStatus({
            params: {
                id: order.id,
                orderStatus: orderStatusSelected
            },
            onCompleted: ressponse => {console.log(ressponse);
                updateOrderData(ressponse.data);
                showSucsessMessage('Update order status successful!');
                setIsOrderStatusUpdating(false);
            },
            onError: err => {
                console.error(err);
                showErrorMessage('Update status order failed. Please try agian!');
                setIsOrderStatusUpdating(false);
            }
        }))
    }

    useEffect(() => {
        if(order) {
            setOrderStatusSelected(order.status);
        }
    }, [order])

    if(!order)
        return <FullScreenLoading/>;

    return (
        <PageWrapper
            breadcrumbs={
                [
                    { breadcrumbName: 'Order', path: paths.orderList },
                    { breadcrumbName: order?.orderCode || '' }
                ]
            }
        >
            <div>
                <Card>
                    <FieldSet title="Order Info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Order Code"
                                    value={order?.orderCode}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Order Status"
                                    value={
                                        <Select
                                            value={orderStatusSelected}
                                            style={{ width: '50%' }}
                                            onChange={status => setOrderStatusSelected(status)}
                                            options={getOrderStatusList}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Payment Method"
                                    value={order?.payment?.paymentMethod}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Payment Status"
                                    value={mappingLabelValue(paymentStatusDDL, order?.payment?.status)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Shipping Method"
                                    value={order?.shippingMethod?.name}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Shipping Price"
                                    value={formatNumber(order?.shippingPrice)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Total Amount"
                                    value={formatNumber(order?.totalAmount)}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Order Created"
                                    value={convertUtcToLocalTimeString(order?.createdDate)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24} align="right">
                                <Button
                                    type="primary"
                                    disabled={isOrderStatusUpdating || !canUpdateOrderStatus}
                                    loading={isOrderStatusUpdating}
                                    onClick={updateOrderStatus}
                                >
                                    Save Change
                                </Button>
                            </Col>
                        </Row>
                    </FieldSet>
                    <FieldSet title="Receiver Info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Full Name"
                                    value={order?.shippingAddress?.fullName}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Phone"
                                    value={order?.shippingAddress?.phone}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Email"
                                    value={order?.userEmail}
                                />
                            </Col>
                            <Col span={12}>
                                <TextOnlyShow
                                    label="Address"
                                    value={order?.shippingAddress?.streetAddress}
                                />
                            </Col>
                        </Row>
                    </FieldSet>
                    <FieldSet title="Product List">
                        <OrderLine orderLines={order?.lines || []}/>
                    </FieldSet>
                    {/* <SaveForm
                        form={form}
                        formId={formId}
                        detailData={detailData}
                        isCreating={isCreating}
                        setDetailData={setDetailData}
                        onSubmit={isCreating ? onCreate : onUpdate}
                    /> */}
                </Card>
            </div>
        </PageWrapper>

    )
}

export default DetailPage;