import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import paths from '@/constants/paths';
import { PublicLayout, MasterLayout, NotFound } from '@/components';

import routes from './routes';
import useAuth from '../hooks/useAuth';

const RootRoutes = () => {
    const { isAuthenticated } = useAuth();
   
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Navigate to={isAuthenticated ? paths.adminList : paths.login} />} />
                {routes.map(route => {
                    const AppLayout = route.isPublic ? PublicLayout : MasterLayout;
                    if (!route.isPublic && !isAuthenticated) {
                        return (
                            <Route exact path={route.path} key={route.path} element={<Navigate replace to={paths.login}/>} />
                        )
                    }
                    return (
                        <Route
                            key={route.path}
                            exact
                            path={route.path}
                            element={
                                <AppLayout>
                                    <route.component />
                                </AppLayout>
                            }
                        />
                        // <Route element={<AppComponent/>} path={route.path} key={route.path}/>
                    );
                })}
                <Route path="*" element={<PublicLayout><NotFound/></PublicLayout>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default RootRoutes;
