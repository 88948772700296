import { useState, useMemo, useRef, useEffect } from 'react';
import { Button, Divider, Modal, Col, Row, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { BaseTable } from '@/components';
import SaveVariantModal from './SaveVariantModal';

import { variantActions } from '@/redux/actions';
import { useNotification, useFetch } from '@/hooks';
import apiConfig from '@/constants/apiConfig';
import { formatNumber } from '@/utils';

const { confirm } = Modal;
const { Text } = Typography;

const VariantList = ({
    detailData,
    setDetailData
}) => {
    const dispatch = useDispatch();
    const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
    const variantSelected = useRef(null);

    const { data: variants, loading, execute: getVariants } = useFetch(apiConfig.variant.getList, {
        params: { productId: detailData.id },
        mappingData: response => response?.data || []
    });

    const { showErrorMessage, showSucsessMessage } = useNotification();

    const onDelete = (id) => {
        dispatch(variantActions.delete({
            params: { id },
            onCompleted: response => {
                if (response.result) {
                    showSucsessMessage('Delete value successful!');
                    getVariants();
                }
                else {
                    showErrorMessage('Delete value failed. Please try again!');
                }
            },
            onError: err => {
                console.log(err);
            }
        }))
    }

    const onConfirmDelete = (id) => {
        confirm({
            title: `Are you sure delete this variant?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => onDelete(id),
            onCancel: () => { }
        });
    }

    const onOpenSaveModal = (attributeValue) => {
        variantSelected.current = attributeValue;
        setIsOpenSaveModal(true);
    }

    const columns = useMemo(() => {
        return [
            { title: 'ID', dataIndex: 'id' },
            { title: 'SKU', dataIndex: 'sku', render: sku => <Text strong>{sku}</Text> },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Price Amount', dataIndex: 'priceAmount', render: priceAmount => formatNumber(priceAmount) },
            {
                title: 'Action',
                width: '80px',
                align: 'center',
                render: dataRow => (
                    <span className="action-col">
                        <Button type="link" onClick={() => onOpenSaveModal(dataRow)}><EditOutlined /></Button>
                        <Divider type="vertical" />
                        <Button onClick={() => onConfirmDelete(dataRow.id)} type="link"><DeleteOutlined /></Button>
                    </span>
                )
            }
        ]
    }, [variants, isOpenSaveModal]);

    useEffect(() => {
        if(detailData?.id) {
            getVariants();
        }
    }, [detailData?.id])

    return (
        <>
            <Row justify="end" style={{ marginBottom: '16px' }}>
                <Col>
                    <Button type="primary" onClick={() => onOpenSaveModal()}><PlusOutlined />New Variant</Button>
                </Col>
            </Row>

            <BaseTable
                rowKey="id"
                columns={columns}
                dataSource={variants}
                pagination={false}
                loading={loading}
            />
            {
                isOpenSaveModal
                    ?
                    <SaveVariantModal
                        data={variantSelected.current}
                        productId={detailData.id}
                        setAttributeData={setDetailData}
                        onClose={() => setIsOpenSaveModal(false)}
                        getVariants={getVariants}
                    />
                    :
                    null
            }

        </>

    );
};

export default VariantList;