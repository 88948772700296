import { createAction } from 'redux-actions';

export const actionTypes = {
    GET_LIST: 'order/GET_LIST',
    GET_DETAIL: 'order/GET_DETAIL',
    UPDATE_ORDER_STATUS: 'order/UPDATE_ORDER_STATUS',
}

export const actions = {
    getList: createAction(actionTypes.GET_LIST),
    getDetail: createAction(actionTypes.GET_DETAIL),
    updateOrderStatus: createAction(actionTypes.UPDATE_ORDER_STATUS),
}

