import { useState, useEffect, useMemo, useRef } from 'react';
import { Button, Card, Switch, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { BaseTable } from '@/components';
import AssignAttributeModal from './AssignAttributeModal';
import { productTypeActions } from '@/redux/actions';
import { useNotification, useFetch } from '@/hooks';
import { attributeTypes } from '@/constants';
import apiConfig from '@/constants/apiConfig';

const { confirm } = Modal;

const AssignedAttributeList = ({
    detailData,
    setDetailData
}) => {
    const dispatch = useDispatch();
    const [hasVariants, setHasVariants] = useState(false);
    const [isOpenAssignAttributeModal, setIsOpenAssignAttributeModal] = useState(false);
    const attributeTypeRef = useRef();

    const { data: attributes, loading: getAttributeListLoading, execute: getAttributeList } = useFetch(
        {
                ...apiConfig.productType.getAttributes,
                path: apiConfig.productType.getAttributes.path.replace(':productTypeId', detailData.id)
        },
        {
            mappingData: response => response?.data || []
        }
    );

    const { data: variants, loading: getVariantListLoading, execute: getVariantList } = useFetch(
        {
                ...apiConfig.productType.getVariants,
                path: apiConfig.productType.getVariants.path.replace(':productTypeId', detailData.id)
        },
        {
            mappingData: response => response?.data || []
        }
    );

    const { showErrorMessage, showSucsessMessage } = useNotification();

    const onChangeHasVariants = (checked) => {
        dispatch(productTypeActions.updateHasVariant({
            params: {
                id: detailData.id,
                hasVariants: checked
            },
            onCompleted: response => {
                if (response.result) {
                    setHasVariants(response?.data?.hasVariants);
                    showSucsessMessage('Update has variants successful!');
                }
                else {
                    showErrorMessage('Update has variants failed. Please try again!');
                }
            },
            onError: err => {
                console.log(err);
            }
        }))
    }

    const onUnassignAttribute = (id, type) => {
        const unassignAction = type === attributeTypes.ATTRIBUTE ? productTypeActions.unassignAtribute : productTypeActions.unassignVariant;
        dispatch(unassignAction({
            params: {
                productTypeId: detailData.id,
                attributeIds: [id]
            },
            onCompleted: response => {
                if (response.result) {
                    const newDetailData = {...detailData};
                    if(type === attributeTypes.ATTRIBUTE) {
                        getAttributeList();
                    }
                    else {
                        getVariantList();
                    }

                    setDetailData(newDetailData);
                    showSucsessMessage('Unassign attribute successful!');
                }
                else {
                    showErrorMessage('Unassign attribute failed. Please try again!');
                }
            },
            onError: err => {
                console.log(err);
                showErrorMessage('Unassign attribute failed. Please try again!');
            }
        }))
    }

    const onConfirmUnassign = (id, type) => {
        confirm({
            title: `Are you sure unassign this attribte?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => onUnassignAttribute(id, type),
            onCancel: () => {}
        });
    }

    const onOpenAssignModal = (type) => {
        attributeTypeRef.current = type;
        setIsOpenAssignAttributeModal(true);
    }

    const attributeColumns = useMemo(() => {
        return [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Slug', dataIndex: 'slug' },
            {
                title: 'Unassign',
                width: '100px',
                align: 'center',
                render: dataRow => <Button onClick={() => onConfirmUnassign(dataRow.id, attributeTypes.ATTRIBUTE)} type="link"><DeleteOutlined/></Button>
            }
        ]
    }, [detailData]);

    const variantColumns = useMemo(() => {
        return [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Slug', dataIndex: 'slug' },
            {
                title: 'Unassign',
                width: '100px',
                align: 'center',
                render: dataRow => <Button onClick={() => onConfirmUnassign(dataRow.id, attributeTypes.VARIANT)} type="link"><DeleteOutlined/></Button>
            }
        ]
    }, [detailData]);

    useEffect(() => {
        getAttributeList();
        if (detailData.hasOwnProperty('hasVariants')) {
            const hasVariants = detailData.hasVariants;
            setHasVariants(detailData.hasVariants);
            if(hasVariants) {
                getVariantList();
            }
        }
    }, [detailData])

    return (
        <>
            <Card
                title="Attribute List"
                extra={<Button type="primary" onClick={() => onOpenAssignModal(attributeTypes.ATTRIBUTE)}><PlusOutlined />Assign Attribute</Button>}
            >
                <BaseTable
                    rowKey="id"
                    columns={attributeColumns}
                    dataSource={attributes}
                    pagination={false}
                    loading={getAttributeListLoading}
                />
            </Card>
            <Card
                style={{
                    marginTop: '24px'
                }}
                title={
                    <span>
                        Has variants: <Switch onChange={onChangeHasVariants} checked={hasVariants}>Has variants</Switch>
                    </span>
                }
                extra={hasVariants && <Button type="primary" onClick={() => onOpenAssignModal(attributeTypes.VARIANT)}><PlusOutlined />Assign Attribute</Button>}
            >
                <BaseTable
                    rowKey="id"
                    columns={variantColumns}
                    dataSource={variants}
                    pagination={false}
                    loading={getVariantListLoading}
                />
            </Card>
            {
                isOpenAssignAttributeModal
                ?
                <AssignAttributeModal
                    isOpen={true}
                    data={detailData}
                    type={attributeTypeRef.current}
                    setDetailData={setDetailData}
                    onClose={() => setIsOpenAssignAttributeModal(false)}
                    getList={attributeTypeRef.current === attributeTypes.ATTRIBUTE ? getAttributeList : getVariantList}
                />
                :
                null
            }
            
        </>

    );
};

export default AssignedAttributeList;