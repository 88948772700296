import { ListPageContainer } from '@/components';
import { productTypeActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Product Type"
            detailUrl ={paths.productTypeDetail}
            createUrl={paths.productTypeCreate}
            breadcrumbs={[
                { breadcrumbName: 'Product Type' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                { title: 'Name', dataIndex: 'name' }
            ]}
            disablePagination={true}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: false
            }}
            getListAction={productTypeActions.getList}
            deleteAction={productTypeActions.delete}
        >
            
        </ListPageContainer>

    )
}

export default ListPage;