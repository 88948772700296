import { Form, Row, Col } from 'antd';

import { TextField, BooleanField, CheckboxField, DropdownField, FieldSet } from '@/components';
import { productAttributeInputTypesDDL } from '@/constants/masterData';

const AttributeForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {


    return (
        <Form
            id={formId}
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            initialValues={isCreating ? { valueRequired: false, visibleInStorefront: false, filterableInStorefront: false } : detailData}
        >
            <FieldSet title="General Infomation">
                <Row gutter={16}>

                    <Col span={12}>
                        <TextField
                            fieldName="name"
                            label="Name"
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <DropdownField
                            fieldName="inputType"
                            label="Input type"
                            required
                            options={productAttributeInputTypesDDL}
                            disabled={!isCreating}
                        />
                    </Col>
                    {
                        !isCreating
                            ?
                            <Col span={12}>
                                <TextField
                                    fieldName="slug"
                                    label="Slug"
                                    disabled
                                />
                            </Col>
                            :
                            null
                    }
                    <Col span={12}>
                        <BooleanField
                            fieldName="valueRequired"
                            label="Value Required"
                        />
                    </Col>

                </Row>
            </FieldSet>
            <FieldSet title="Storefront Properties">
                <Row gutter={16}>
                    <Col span={24}>

                        <Row gutter={16}>
                            <Col span={12}>
                                <CheckboxField fieldName="filterableInStorefront">
                                    Use in Faceted Navigation
                                </CheckboxField>
                            </Col>
                            <Col span={12}>
                                <CheckboxField fieldName="visibleInStorefront">
                                    Visible on Product Page in Storefront
                                </CheckboxField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FieldSet>
        </Form>
    );
};

export default AttributeForm;