import { useState, useMemo, useRef } from 'react';
import { Button, Card, Divider, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { BaseTable } from '@/components';
import AssignAttributeValueModal from './SaveAttributeValueModal';
import { attributeValueActions } from '@/redux/actions';
import { useNotification } from '@/hooks';

const { confirm } = Modal;

const AttributeValueList = ({
    detailData,
    setDetailData
}) => {
    const dispatch = useDispatch();
    const [isOpenAssignModal, setIsOpenAssignModal] = useState(false);
    const attributeValueSelected = useRef(null);

    const { showErrorMessage, showSucsessMessage } = useNotification();

    const onUnassign = (id) => {
        dispatch(attributeValueActions.delete({
            params: { id },
            onCompleted: response => {
                if (response.result) {
                    const newDetailData = {...detailData};
                    newDetailData.values = detailData.values.filter(attributeValue => attributeValue.id !== id);
                    setDetailData(newDetailData);
                    showSucsessMessage('Delete value successful!');
                }
                else {
                    showErrorMessage('Delete value failed. Please try again!');
                }
            },
            onError: err => {
                console.log(err);
            }
        }))
    }

    const onConfirmUnassign = (id) => {
        confirm({
            title: `Are you sure delete this value?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => onUnassign(id),
            onCancel: () => {}
        });
    }

    const onOpenAssignModal = (attributeValue) => {
        attributeValueSelected.current = attributeValue;
        setIsOpenAssignModal(true);
    }

    const attributeValueColumns = useMemo(() => {
        return [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Slug', dataIndex: 'slug' },
            {
                title: 'Action',
                width: '80px',
                align: 'center',
                render: dataRow => (
                    <span className="action-col">
                        <Button type="link" onClick={() => onOpenAssignModal(dataRow)}><EditOutlined/></Button>
                        <Divider type="vertical" />
                        <Button onClick={() => onConfirmUnassign(dataRow.id)} type="link"><DeleteOutlined/></Button>
                    </span>
                )
            }
        ]
    }, [detailData]);

    const attributeValues = useMemo(() => {
        return detailData?.values || [];
    }, [detailData?.values])

    return (
        <>
            <Card
                title="Attribute Value List"
                extra={<Button type="primary" onClick={() => onOpenAssignModal()}><PlusOutlined />Assign Value</Button>}
            >
                <BaseTable
                    rowKey="id"
                    columns={attributeValueColumns}
                    dataSource={attributeValues}
                    pagination={false}
                />
            </Card>
            {
                isOpenAssignModal
                ?
                <AssignAttributeValueModal
                    isOpen={true}
                    data={attributeValueSelected.current}
                    attributeData={detailData}
                    setAttributeData={setDetailData}
                    onClose={() => setIsOpenAssignModal(false)}
                />
                :
                null
            }
            
        </>

    );
};

export default AttributeValueList;