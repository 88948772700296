import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import ProductTypeForm from './ProductTypeForm';
import AssignedAttributeList from './AssignedAttributeList';

import paths from '@/constants/paths';
import { productTypeActions } from '@/redux/actions';

const GENERAL_TAB = 'general-tab';
const ATTRBUTE_TAB = 'attribute-tab';

const SavePage = () => {
    const [activeTab, setActiveTab] = useState(GENERAL_TAB);
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Product Types', path: paths.productTypeList },
            { breadcrumbName: isCreating ? 'Create' : data?.name || '' }
        ];
    }

    const onChangeTab = (tab) => {
        setActiveTab(tab);
    }
    const isGeneralTab = activeTab === GENERAL_TAB;
    
    return (
        <SavePageContainer
            form={isGeneralTab ? ProductTypeForm : AssignedAttributeList}
            isCreating={!id}
            objectName="product type"
            tabs={[
                {
                    key: GENERAL_TAB,
                    label: 'General'
                },
                (!isCreating && {
                    key: ATTRBUTE_TAB,
                    label: 'Attributes & Vatiants'
                })
            ]}
            disableActions={!isGeneralTab}
            listUrl={paths.productTypeList}
            detailUrl={paths.productTypeDetail}
            createAction={productTypeActions.create}
            updateAction={productTypeActions.update}
            getDetailAction={productTypeActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            onChangeTab={onChangeTab}
        />
    )
}

export default SavePage;