import { useState } from 'react';
import { useParams } from 'react-router-dom';

import paths from '@/constants/paths';
import { productActions } from '@/redux/actions';

import { SavePageContainer } from '@/components';
import ProductForm from './ProductForm';
import VariantList from './VariantList';
import AttributeForm from './AttributeForm';

const GENERAL_TAB = 'general-tab';
const VARIANT_TAB = 'variant-tab';
const ATTRIBUTE_TAB = 'attribute-tab';

const SavePage = () => {
    const [activeTab, setActiveTab] = useState(GENERAL_TAB);
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Products', path: paths.productList },
            { breadcrumbName: isCreating ? 'Create' : data?.name || '' }
        ];
    }

    const isGeneralTab = activeTab === GENERAL_TAB;
    const isVariantTab = activeTab === VARIANT_TAB;

    return (
        <SavePageContainer
            form={isGeneralTab ? ProductForm : isVariantTab ? VariantList : AttributeForm}
            isCreating={!id}
            disableActions={isVariantTab}
            objectName="product"
            listUrl={paths.productList}
            detailUrl={paths.productDetail}
            createAction={productActions.create}
            updateAction={isGeneralTab ? productActions.update : productActions.updateAttribute}
            getDetailAction={productActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            getDetailDataMapping={data => ({ ...data, categoryId: data.category?.id, productTypeId: data.productType?.id, })}
            onChangeTab={setActiveTab}
            tabs={[
                {
                    key: GENERAL_TAB,
                    label: 'General'
                },
                ...(!isCreating ? [
                    {
                        key: VARIANT_TAB,
                        label: 'Variants'
                    },
                    {
                        key: ATTRIBUTE_TAB,
                        label: 'Attributes'
                    },
                ] : [])
            ]}
        />
    )
}

export default SavePage;