import { Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { ListPageContainer } from '@/components';
import { orderActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatNumber, mappingLabelValue } from '@/utils';
import { convertUtcToLocalTimeString } from '@/utils/datetime';
import { orderStatusDDL, paymentStatusDDL } from '@/constants/masterData';
import { paymentStatus, fieldTypes, DATETIME_FORMAT_VALUE } from '@/constants';

const { Text } = Typography;

const ListPage = () => {
    const [searchParams] = useSearchParams();

    const getPaymentStatusTag = status => {
        let tagColor;
        switch(status) {
            case paymentStatus.PENDING:
                tagColor = 'processing';
                break;
            case paymentStatus.CHARGED:
                tagColor = 'success';
                break;
            case paymentStatus.CANCELLED:
                tagColor = 'error';
                break;
            case paymentStatus.REFUNDED:
                tagColor = 'orange';
                break;
            default:
                tagColor = 'default';
                break;
        }
        return <Tag color={tagColor}>{mappingLabelValue(paymentStatusDDL, status)}</Tag>
    }

    const mappingSearchParams = (searchFields) => {
        const filter = {};
        searchFields?.forEach(searchField => {
            if (searchParams.has(searchField.key)) {
                if (searchField.key === 'fromDate') {
                    filter[searchField.key] = dayjs(searchParams.get(searchField.key)).startOf("date").format(DATETIME_FORMAT_VALUE);
                } else if (searchField.key === 'toDate') {
                    filter[searchField.key] = dayjs(searchParams.get(searchField.key)).endOf("date").format(DATETIME_FORMAT_VALUE);
                } else {
                    filter[searchField.key] = searchParams.get(searchField.key);
                }

            }
        });
        return filter;
    }


    return (
        <ListPageContainer
            objectName="Order"
            detailUrl ={paths.orderDetail}
            breadcrumbs={[
                { breadcrumbName: 'Order' }
            ]}
            columns={[
                { title: 'Order Code', dataIndex: 'orderCode', render: orderCode => <Text strong>{orderCode}</Text> },
                { title: 'Email', dataIndex: 'userEmail' },
                { title: 'Order Status', dataIndex: 'orderStatus', render: orderStatus => mappingLabelValue(orderStatusDDL, orderStatus) },
                { title: 'Payment Status', dataIndex: 'paymentStatus', render: status => getPaymentStatusTag(status) },
                { title: 'Payment Method', dataIndex: 'paymentMethod'},
                { title: 'Total Amount', dataIndex: 'totalAmount', render: totalAmount => formatNumber(totalAmount) },
                { title: 'Created', dataIndex: 'createdDate', render: createdDate => convertUtcToLocalTimeString(createdDate)}
            ]}
            searchFields={[
                { key: 'orderCode', searchPlaceholder: 'Order Code', gridCol: 6 },
                {
                    key: 'orderStatus',
                    searchPlaceholder: 'Order Status',
                    fieldType: fieldTypes.SELECT,
                    options: orderStatusDDL,
                    gridCol: 6
                },
                {
                    key: 'fromDate',
                    searchPlaceholder: 'Form Date',
                    gridCol: 6,
                    fieldType: fieldTypes.DATE,
                },
                {
                    key: 'toDate',
                    searchPlaceholder: 'To Date',
                    gridCol: 6,
                    fieldType: fieldTypes.DATE,
                },
    
    
            ]}
            actionBar={{
                isEdit: true,
                isCreate: false,
                isDelete: false
            }}
            getListAction={orderActions.getList}
            mappingSearchParams={mappingSearchParams}
        />

    )
}

export default ListPage;