import { useSelector, useDispatch } from 'react-redux';

import { storageKeys } from '@/constants';
import { getLocalStorage } from '@/utils/localStorage';
import { accountActions, accountActionTypes } from '@/redux/actions';
// import { useNotification } from '@hooks';
// import { commonMessages } from '@constants/intl';

const useAuth = () => {
    const dispatch = useDispatch();

    const { profileData } = useSelector(state => state?.account || {});
    const loading = useSelector(state => state?.loading[accountActionTypes.GET_PROFILE]);
    const logout = () => {
        dispatch(accountActions.logout());
    }

    const hasRoles = (roles = []) => {
        return profileData?.roles?.some(role => roles.includes(role.name));
    }

    return {
        isAuthenticated: !!getLocalStorage(storageKeys.ACCESS_TOKEN),
        loading,
        user: profileData,
        logout,
        hasRoles
    }
}

export default useAuth;
