import { commonStatus, productAttributeInputTypes, orderStatus, paymentStatus } from '.';

export const statusDDL = [
    { value: commonStatus.ACTIVE, label: 'Active' },
    { value: commonStatus.LOCK, label: 'Lock' }
]

export const productAttributeInputTypesDDL = [
    { value: productAttributeInputTypes.DROPDOWN, label: 'Dropdown' },
    // { value: productAttributeInputTypes.MULTISELECT, label: 'MultiSelect' },
    { value: productAttributeInputTypes.PLAIN_TEXT, label: 'PlainText' },
    // { value: productAttributeInputTypes.RICH_TEXT, label: 'RichText' }
]

export const orderStatusDDL = [
    { value: orderStatus.UNFULFILLED, label: 'Unfulfilled' },
    { value: orderStatus.FULFILLED, label: 'Fulfilled' },
    { value: orderStatus.DELIVERY, label: 'Delivery' },
    { value: orderStatus.COMPLETED, label: 'Completed' },
    { value: orderStatus.CANCELED, label: 'Cancelled' }
]

export const paymentStatusDDL = [
    { value: paymentStatus.NOT_CHARGED, label: 'Not charged' },
    { value: paymentStatus.PENDING, label: 'Pending' },
    { value: paymentStatus.REFUNDED, label: 'Refuned' },
    { value: paymentStatus.CANCELLED, label: 'Cencelled' },
    { value: paymentStatus.CHARGED, label: 'Charged' }
]