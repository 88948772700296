import React from 'react';

import { Input } from 'antd';

import FormItem from './FormItem';

const { TextArea } = Input;

const TextField = ({
    maxLength = 255,
    maxLengthMsg,
    type,
    disabled,
    onBlur,
    defaultValue,
    prefix,
    ...formItemProps
}) => {

    const getMaxLengthMsg = () => {
        return maxLengthMsg || `This field cannot be longer than ${maxLength} characters`;
    }

    const getTextFieldRules = () => {
        const rules = [];
        if (maxLength) {
            rules.push({ max: maxLength, message: getMaxLengthMsg() });
        }
        return rules;
    }

    const InputText = ({ getPlaceHolder, ...inputProps }) => {
        if(type === 'textarea') {
            return (
                <TextArea
                    placeholder={getPlaceHolder()}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    onBlur={onBlur}
                    autoSize={inputProps?.rows ? { minRows: inputProps.rows, maxRows: inputProps.rows } : { minRows: 3, maxRows: 3 }}
                    {...inputProps}
                />
            )
        }

        return (
            <Input
                placeholder={getPlaceHolder()}
                defaultValue={defaultValue}
                disabled={disabled}
                type={type}
                onBlur={onBlur}
                prefix={prefix}
                {...inputProps}
            />
        )
    }

    return (
        <FormItem
            {...formItemProps}
            fieldRules={getTextFieldRules()}
        >
            <InputText />
        </FormItem>
    )
}

export default TextField;
