import { createAction } from 'redux-actions';

export const actionTypes = {
    LOGIN: 'account/LOGIN',
    LOGOUT: 'account/LOGOUT',
    GET_PROFILE: 'account/GET_PROFILE',
    REFRESHING_TOKEN: 'account/REFRESHING_TOKEN',
    REFRESH_TOKEN: 'account/REFRESH_TOKEN',
    UPDATE_TOKEN: 'account/UPDATE_TOKEN',
}

export const actions = {
    login: createAction(actionTypes.LOGIN),
    logout: createAction(actionTypes.LOGOUT),
    getProfile: createAction(actionTypes.GET_PROFILE),
    refreshingToken: createAction(actionTypes.REFRESHING_TOKEN),
    refreshToken: createAction(actionTypes.REFRESH_TOKEN),
    updateToken: createAction(actionTypes.UPDATE_TOKEN),
}

