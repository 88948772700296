import { createAction } from 'redux-actions';

export const actionTypes = {
    GET_LIST: 'productType/GET_LIST',
    GET_DETAIL: 'productType/GET_DETAIL',
    CREATE: 'productType/CREATE',
    UPDATE: 'productType/UPDATE',
    DELETE: 'productType/DELETE',
    UPDATE_HAS_VARIANT: 'productType/UPDATE_HAS_VARIANT',
    ASSIGN_ATTRIBUTE: 'productType/ASSIGN_ATTRIBUTE',
    UNASSIGN_ATTRIBUTE: 'productType/UNASSIGN_ATTRIBUTE',
    ASSIGN_VARIANT: 'productType/ASSIGN_VARIANT',
    UNASSIGN_VARIANT: 'productType/UNASSIGN_VARIANT',
}

export const actions = {
    getList: createAction(actionTypes.GET_LIST),
    getDetail: createAction(actionTypes.GET_DETAIL),
    create: createAction(actionTypes.CREATE),
    update: createAction(actionTypes.UPDATE),
    delete: createAction(actionTypes.DELETE),
    updateHasVariant: createAction(actionTypes.UPDATE_HAS_VARIANT),
    assignAtribute: createAction(actionTypes.ASSIGN_ATTRIBUTE),
    unassignAtribute: createAction(actionTypes.UNASSIGN_ATTRIBUTE),
    assignVariant: createAction(actionTypes.ASSIGN_VARIANT),
    unassignVariant: createAction(actionTypes.UNASSIGN_VARIANT),
}

