import paths from '@/constants/paths';
import { userRoles } from '@/constants';

import LoginPage from '@/containers/login/LoginPage';
import AdminListPage from '@/containers/admin/ListPage';
import AdminSavePage from '@/containers/admin/SavePage';

import ProductTypeListPage from '@/containers/productType/ListPage';
import ProductTypeSavePage from '@/containers/productType/SavePage';

import AttributeListPage from '@/containers/attribute/ListPage';
import AttributeSavePage from '@/containers/attribute/SavePage';

import CategoryListPage from '@/containers/category/ListPage';
import CategorySavePage from '@/containers/category/SavePage';

import CollectionListPage from '@/containers/collection/ListPage';
import CollectionSavePage from '@/containers/collection/SavePage';

import ProductListPage from '@/containers/product/ListPage';
import ProductSavePage from '@/containers/product/SavePage';

import OrderListPage from '@/containers/order/ListPage';
import OrderDetailPage from '@/containers/order/DetailPage';

import PageListPage from '@/containers/page/ListPage';
import PageSavePage from '@/containers/page/SavePage';

const routes = [
    // forbidden: {
    //     path: '/forbidden',
    //     isPublic: false,
    //     component: Forbidden
    // },
    {
        path: paths.login,
        isPublic: true,
        component: LoginPage
    },
    {
        path: paths.adminList,
        component: AdminListPage
    },
    {
        path: paths.adminCreate,
        component: AdminSavePage
    },
    {
        path: paths.adminDetail,
        component: AdminSavePage
    },
    {
        path: paths.productTypeList,
        component: ProductTypeListPage,
        roles: [userRoles.SUPPER_ADMIN]
    },
    {
        path: paths.productTypeCreate,
        component: ProductTypeSavePage,
        roles: [userRoles.SUPPER_ADMIN]
    },
    {
        path: paths.productTypeDetail,
        component: ProductTypeSavePage,
        roles: [userRoles.SUPPER_ADMIN]
    },
    {
        path: paths.attributeList,
        component: AttributeListPage,
    },
    {
        path: paths.attributeCreate,
        component: AttributeSavePage,
    },
    {
        path: paths.attributeDetail,
        component: AttributeSavePage,
    },
    {
        path: paths.categoryList,
        component: CategoryListPage,
    },
    {
        path: paths.categoryCreate,
        component: CategorySavePage,
    },
    {
        path: paths.categoryDetail,
        component: CategorySavePage,
    },
    {
        path: paths.collectionList,
        component: CollectionListPage,
    },
    {
        path: paths.collectionCreate,
        component: CollectionSavePage,
    },
    {
        path: paths.collectionDetail,
        component: CollectionSavePage,
    },
    {
        path: paths.productList,
        component: ProductListPage,
    },
    {
        path: paths.productCreate,
        component: ProductSavePage,
    },
    {
        path: paths.productDetail,
        component: ProductSavePage,
    },
    {
        path: paths.orderList,
        component: OrderListPage,
    },
    {
        path: paths.orderDetail,
        component: OrderDetailPage,
    },
    {
        path: paths.pageList,
        component: PageListPage,
    },
    {
        path: paths.pageCreate,
        component: PageSavePage,
    },
    {
        path: paths.pageDetail,
        component: PageSavePage,
    },
]

export default routes;
