import { Form, Row, Col } from 'antd';

import { TextField, BooleanField, NumericField } from '@/components/Form';

const ProductTypeForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {

    const isShippingRequired = Form.useWatch('isShippingRequired', form);

    return (
        <Form
            id={formId}
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            initialValues={isCreating ? { isShippingRequired: false } : detailData}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Name"
                        required
                    />
                </Col>
                <Col span={12}>
                    <BooleanField
                        fieldName="isShippingRequired"
                        label="Required shipping"
                        required
                    />
                </Col>

                {
                    isShippingRequired
                        ?
                        <Col span={12}>
                            <NumericField
                                fieldName="weight"
                                label="Weight(KG)"
                                placeholder="Input weight"
                                min={0.1}
                                max={10000}
                                required
                            />
                        </Col>
                        :
                        null
                }
            </Row>
        </Form>
    );
};

export default ProductTypeForm;