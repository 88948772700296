import { useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'antd';
import { useDispatch } from 'react-redux';

import { TextField, NumericField } from '@/components';

import { useNotification } from '@/hooks';
import { attributeValueActions } from '@/redux/actions';

import styles from './SaveAttributeValueModal.module.scss';

const formId = 'assign-value-form';

const SaveAttributeValueModal = ({
    isOpen,
    data,
    attributeData,
    onClose,
    setAttributeData
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isCreating = !data?.id;
    const action = isCreating ? 'Create' : 'Update';

    const dispatch = useDispatch();
    const { showErrorMessage, showSucsessMessage } = useNotification();
    const onSubmit = (values) => {
        const saveAction = isCreating ? attributeValueActions.create : attributeValueActions.update;
        setLoading(true);
        dispatch(saveAction({
            params: {
                ...(!isCreating && { id: data.id }),
                attributeId: attributeData.id,
                name: values.name,
                sortOrder: values.sortOrder,
                value: values.value
            },
            onCompleted: (response) => {
                if (response.result) {
                    let attribteValues = [];
                    const valueAdded = response.data;
                    if(isCreating) {
                        attribteValues = [...attributeData.values, valueAdded];
                    }
                    else {
                        attribteValues = attributeData.values.map(atributeValue => {
                            if(atributeValue.id === valueAdded.id) {
                                return valueAdded;
                            }
                            return atributeValue;
                        })
                    }
                    attributeData.values = attribteValues;
                    setAttributeData(attributeData);
                    showSucsessMessage(`${action} successful!`);
                    onClose();
                }
                else {
                    showErrorMessage(`${action}  failed. Please try again!`);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                showErrorMessage(err?.message || `${action} failed. Please try again!`);
                setLoading(false);
            }
        }));
    }

    return (
        <Modal
            maskClosable={false}
            bodyStyle={{ maxHeight: '80vh', minHeight: '200px', overflow: 'auto' }}
            destroyOnClose // rerender child component when modal close
            style={{ top: 60 }}
            width={600}
            open={isOpen}
            title={`${isCreating ? 'Create' : 'Update'} Attribute Value`}
            className={styles.attributeValueModal}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>Cancel</Button>,
                <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form={formId}
                    loading={loading}
                >
                    Save
                </Button>
            ]}
        >
            <Form
                id={formId}
                onFinish={onSubmit}
                form={form}
                layout="vertical"
                initialValues={data}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <TextField
                            fieldName="name"
                            label="Name"
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <TextField
                            fieldName="value"
                            label="Value"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <NumericField
                            fieldName="sortOrder"
                            label="Sort order"
                        />
                    </Col>
                </Row>
                
            </Form>
        </Modal>
    )
}

export default SaveAttributeValueModal;
