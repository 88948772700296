import { useEffect, useState, useMemo } from 'react';
import { Button, Modal, Input, Typography, Row, Col, List, Space, Checkbox } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';

import { attributeActions, productTypeActions } from '@/redux/actions';
import { useDebouncedCallback, useNotification } from '@/hooks';
import { attributeTypes, productAttributeInputTypes } from '@/constants';

import styles from './AssignAttributeModal.module.scss';

const { Search } = Input;
const { Text } = Typography;

const PAGE_SIZE = 10;

const AssignAttributeModal = ({
    isOpen,
    data,
    type,
    onClose,
    getList
}) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMore, setIsMore] = useState(false);
    const [page, setPage] = useState(0);

    const dispatch = useDispatch();
    const { showErrorMessage, showSucsessMessage } = useNotification();

    const attributes = useMemo(() => {
        return data?.attributes || [];
    }, [data])

    const onSearch = useDebouncedCallback((evt) => {
        const keyword = evt.target.value;
        getAttributes(0, keyword)
    }, 300)

    const getAttributes = (page = 0, search = '') => {
        const params = {
            page,
            size: PAGE_SIZE,
            name: search,
            productTypeIdNot: data.id,
            ...(type === attributeTypes.VARIANT && { inputType: productAttributeInputTypes.DROPDOWN })
        };
        setListLoading(true);
        setPage(page);
        dispatch(attributeActions.getList({
            params,
            onCompleted: ({ data }) => {
                const { content, last } = data || {};
                let list = content || [];
                if (page > 0) {
                    list = [...dataList, ...content];
                }
                setDataList(list);
                setListLoading(false);
                setIsMore(!last);
            },
            onError: (err) => {
                console.log(err);
                setListLoading(false);
            }
        }));
    }

    const onOk = () => {
        setLoading(true);
        const assignAction = type === attributeTypes.ATTRIBUTE ? productTypeActions.assignAtribute : productTypeActions.assignVariant;
        dispatch(assignAction({
            params: {
                productTypeId: data.id,
                attributeIds: selectedRowKeys
            },
            onCompleted: (response) => {
                if (response.result) {
                    getList();
                    showSucsessMessage('Assign successful!');
                    onClose();
                }
                else {
                    showErrorMessage('Assign failed. Please try again!');
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                showErrorMessage(err?.message || 'Assign failed. Please try again!');
                setLoading(false);
            }
        }));
    }

    const loadMoreData = () => {
        if (listLoading) {
            return;
        }
        getAttributes(page + 1);
    }

    const onSelectRow = (checked, id) => {
        let checkedList = [...selectedRowKeys];
        if (checked) {
            checkedList.push(id);
        }
        else {
            checkedList = checkedList.filter(item => item !== id);
        }
        setSelectedRowKeys(checkedList);
    }

    useEffect(() => {
        getAttributes();
    }, []);

    useEffect(() => {
        if (selectedRowKeys?.length) {
            const checkedList = selectedRowKeys.filter(id => dataList?.some(listItem => listItem.id === id));
            setSelectedRowKeys(checkedList);
        }
    }, [dataList])

    return (
        <Modal
            maskClosable={false}
            bodyStyle={{ maxHeight: '80vh', minHeight: '200px', overflow: 'auto' }}
            destroyOnClose // rerender child component when modal close
            style={{ top: 60 }}
            width={600}
            open={isOpen}
            title={'Assign attribute'}
            className={styles.assignAttributeModal}
            onCancel={onClose}
            footer={[
                <Row key="all" justify="space-between" align="middle">
                    <Col>
                        {!!selectedRowKeys?.length && (
                            <>
                                <Text type="secondary"><i>Bạn đã chọn: </i></Text>
                                <Text><i>{selectedRowKeys.length} mục</i></Text>
                            </>
                        )}
                    </Col>
                    <Col>
                        <Button key="back" onClick={onClose}>Cancel</Button>
                        <Button
                            disabled={!selectedRowKeys?.length}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={onOk}
                        >
                            Assign attribute
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Search onChange={onSearch} placeholder={`Search by name`} allowClear />
            <div className={styles.listWrapper}>
                <InfiniteScroll
                    dataLength={dataList?.length}
                    next={loadMoreData}
                    hasMore={isMore}
                    height={400}
                    // loader={listLoading ? <center><Spin/></center> : null}
                    // loader={<center><Spin/></center>}
                    loading={listLoading}
                >
                    <List
                        className={styles.list}
                        dataSource={dataList}
                        loading={listLoading}
                        renderItem={(item) => {
                            const assigned = attributes.some(attribute => attribute.id === item.id);
                            return (
                                <List.Item key={item.email} className={styles.listItem}>
                                    <Checkbox
                                        onChange={(evt) => onSelectRow(evt.target.checked, item.id)}
                                        checked={assigned || selectedRowKeys.includes(item.id)}
                                        disabled={assigned}
                                    />
                                    <Space direction="vertical" className={styles.content}>
                                        <Text strong>{item.name}</Text>
                                        <Text>{item.slug}</Text>
                                    </Space>
                                </List.Item>
                            )
                        }}
                    />
                </InfiniteScroll>
            </div>
            {/* </div> */}
        </Modal>
    )
}

export default AssignAttributeModal;
