import { useMemo } from 'react';
import { Typography } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { BaseTable } from '@/components';
import { formatNumber } from '@/utils';

const { Text } = Typography;

const OrderLine = ({ orderLines }) => {
    console.log(orderLines);
    const columns = useMemo(() => {
        return [
            { title: 'SKU', dataIndex: 'variant', render: variant => <Text strong>{variant.sku}</Text> },
            {
                title: 'Product Image',
                dataIndex: 'variant',
                render: variant => variant?.product?.image?.url ? <img src={variant?.product?.image?.url} style={{ heigth: 42, width: 42 }} alt={variant?.product.alt} /> : <PictureOutlined className="empty-img-col" />
            },
            { title: 'Product Name', dataIndex: 'variant', render: variant => variant.product.name },
            { title: 'Price Amount', dataIndex: 'unitPriceAmount', render: unitPriceAmount => formatNumber(unitPriceAmount) },
            { title: 'Quantity', dataIndex: 'quantity' }
        ]
    }, []);

    return (
        <BaseTable
            rowKey="id"
            columns={columns}
            dataSource={orderLines}
            pagination={false}
        />
    )
}

export default OrderLine;
