import { createAction } from 'redux-actions';

export const actionTypes = {
    GET_LIST: 'collection/GET_LIST',
    GET_DETAIL: 'collection/GET_DETAIL',
    CREATE: 'collection/CREATE',
    UPDATE: 'collection/UPDATE',
    DELETE: 'collection/DELETE',
    ASSIGN_PRODUCT: 'collection/ASSIGN_PRODUCT',
    UNASSIGN_PRODUCT: 'collection/UNASSIGN_PRODUCT',
    UPDATE_ASSIGNED_PRODUCT: 'collection/UPDATE_ASSIGNED_PRODUCT',
}

export const actions = {
    getList: createAction(actionTypes.GET_LIST),
    getDetail: createAction(actionTypes.GET_DETAIL),
    create: createAction(actionTypes.CREATE),
    update: createAction(actionTypes.UPDATE),
    delete: createAction(actionTypes.DELETE),
    assignProduct: createAction(actionTypes.ASSIGN_PRODUCT),
    unassignProduct: createAction(actionTypes.UNASSIGN_PRODUCT),
    updateAssignedProduct: createAction(actionTypes.UPDATE_ASSIGNED_PRODUCT),
}

