import { useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'antd';
import { useDispatch } from 'react-redux';

import { NumericField } from '@/components';

import { useNotification } from '@/hooks';
import { collectionActions } from '@/redux/actions';

import styles from './SaveAssignedProductModal.module.scss';

const formId = 'assigned-product-form';

const SaveAssignedProductModal = ({
    isOpen,
    data,
    getList,
    onClose,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { showErrorMessage, showSucsessMessage } = useNotification();
    
    const onSubmit = (values) => {
        setLoading(true);
        dispatch(collectionActions.updateAssignedProduct({
            params: {
                id: data.id,
                sortOrder: values.sortOrder
            },
            onCompleted: (response) => {
                if (response.result) {
                    showSucsessMessage(`Update successful!`);
                    getList();
                    onClose();
                }
                else {
                    showErrorMessage(`Update  failed. Please try again!`);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                showErrorMessage(err?.message || `Update failed. Please try again!`);
                setLoading(false);
            }
        }));
    }

    return (
        <Modal
            maskClosable={false}
            bodyStyle={{ maxHeight: '80vh', minHeight: '200px', overflow: 'auto' }}
            destroyOnClose // rerender child component when modal close
            style={{ top: 60 }}
            width={600}
            open={isOpen}
            title={'Update Product Assigned'}
            className={styles.assignedProductModal}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>Cancel</Button>,
                <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form={formId}
                    loading={loading}
                >
                    Save
                </Button>
            ]}
        >
            <Form
                id={formId}
                onFinish={onSubmit}
                form={form}
                layout="vertical"
                initialValues={data}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <NumericField
                            fieldName="sortOrder"
                            label="Sort order"
                            required
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default SaveAssignedProductModal;
