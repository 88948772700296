import { takeLatest } from 'redux-saga/effects';

import { attributeValueActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    CREATE,
    UPDATE,
    DELETE,
} = attributeValueActionTypes;

const create = (action) => {
    return processCallbackAction(apiConfig.attributeValue.create, action);
}

const update = (action) => {
    return processCallbackAction(apiConfig.attributeValue.update, action);
}

const deleteAttributeValue = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.attributeValue.delete,
        path: apiConfig.attributeValue.delete.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const attributeValueSagas = [
    takeLatest(CREATE, create),
    takeLatest(UPDATE, update),
    takeLatest(DELETE, deleteAttributeValue)
];

export default attributeValueSagas;