import { useState    } from 'react';
import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import AttributeForm from './AttributeForm';
import AttributeValueList from './AttributeValueList';

import paths from '@/constants/paths';
import { productAttributeInputTypes } from '@/constants'
import { attributeActions } from '@/redux/actions';

const GENERAL_TAB = 'general-tab';
const VALUE_TAB = 'value-tab';

const SavePage = () => {
    const [activeTab, setActiveTab] = useState(GENERAL_TAB);
    const { id } = useParams();
    const isCreating = !id;
    
    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Attributes', path: paths.attributeList },
            { breadcrumbName: isCreating ? 'Create' : data?.name || '' }
        ];
    }

    const getTabs = (data) => {
        const tabs = [
            {
                key: GENERAL_TAB,
                label: 'General'
            }
        ];

        if(data?.inputType === productAttributeInputTypes.DROPDOWN 
            || data?.inputType === productAttributeInputTypes.MULTISELECT ) {
                tabs.push({
                    key: VALUE_TAB,
                    label: 'Attribute Values'
                })
        }

        return tabs;
    }

    const onChangeTab = (tab) => {
        setActiveTab(tab);
    }

    const isGeneralTab = activeTab === GENERAL_TAB;

    return (
        <SavePageContainer
            form={isGeneralTab ? AttributeForm : AttributeValueList}
            isCreating={!id}
            disableActions={!isGeneralTab}
            objectName="attribute"
            listUrl={paths.attributeList}
            detailUrl={paths.attributeDetail}
            createAction={attributeActions.create}
            updateAction={attributeActions.update}
            getDetailAction={attributeActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            onChangeTab={onChangeTab}
            getTabs={getTabs}
        />
    )
}

export default SavePage;