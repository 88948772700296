import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Form } from 'antd';
import { useDispatch } from 'react-redux';


import { PageWrapper } from '@/components';
import { useNotification } from '@/hooks';

import styles from './index.module.scss';

const SavePageContainer = ({
    form: SaveForm,
    isCreating,
    objectName,
    actionButtons,
    disableActions,
    listUrl,
    detailUrl,
    tabs: tabsProp,
    isUpdateRefresh,
    createAction,
    updateAction,
    getDetailAction,
    onCreate: onCreateProp,
    onUpdate: onUpdateProp,
    getBreadcrumbs,
    getTabs,
    onChangeTab,
    getDetailDataMapping,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState();
    const [detailData, setDetailData] = useState({});
    const { id } = useParams();
    const [form] = Form.useForm();

    const { showErrorMessage, showSucsessMessage } = useNotification();

    const formId = `form-${objectName}`;
    const breadcrumbs = (getBreadcrumbs && getBreadcrumbs(detailData)) || [];
    const tabs = (getTabs && getTabs(detailData)) || tabsProp;

    const onCancel = () => {
        if (listUrl) {
            navigate(listUrl);
        }
    }

    const onCreate = (values) => {
        if (onCreateProp) {
            onCreateProp(values);
        }
        else if (createAction) {
            setIsSubmitting(true);
            dispatch(createAction({
                params: values,
                onCompleted: response => {
                    if (response?.result) {
                        onSaveSuccess(response.data);
                    }
                    else {
                        onSaveFail(response);
                    }
                },
                onError: err => {
                    onSaveFail(err);
                }
            }))
        }
    }

    const onUpdate = (values) => {
        if (onUpdateProp) {
            onUpdateProp(values);
        }
        else if (updateAction) {
            setIsSubmitting(true);
            dispatch(updateAction({
                params: { id: detailData.id, ...values },
                onCompleted: response => {
                    if (response?.result) {
                        if(isUpdateRefresh) {
                            setDetailData(response.data);
                        }
                        onSaveSuccess(response.data);
                    }
                    else {
                        onSaveFail(response);
                    }
                },
                onError: err => {
                    onSaveFail(err);
                }
            }))
        }
    }

    const onSaveFail = (err) => {
        const action = isCreating ? 'Create' : 'Update';
        const errMsg = err?.message || `${action} ${objectName} failed. Please try again!`;
        setIsSubmitting(false);
        showErrorMessage(errMsg);
    }

    const onSaveSuccess = (data) => {
        const action = isCreating ? 'Create' : 'Update';
        setIsSubmitting(false);
        showSucsessMessage(`${action} ${objectName} successful!`);
        if (isCreating && data.id) {
            navigate(detailUrl.replace(':id', data.id));
        }
    }

    const onGetDetailFail = useCallback((err) => {
        showErrorMessage(`Get data failed. Please try again!`);
        navigate(listUrl);
    }, [navigate, showErrorMessage, listUrl])

    useEffect(() => {
        if (!isCreating && id && getDetailAction) {
            dispatch(getDetailAction({
                params: { id },
                onCompleted: response => {
                    if (response?.result) {
                        const data = getDetailDataMapping ? getDetailDataMapping(response.data) : response.data;
                        setDetailData(data);
                    }
                    else {
                        onGetDetailFail(response);
                    }
                },
                onError: err => {
                    onGetDetailFail(err);
                }
            }))
        }
    }, [isCreating, id])

    useEffect(() => {
        form.setFieldsValue(detailData);
    }, [form, detailData])

    return (
        <PageWrapper
            breadcrumbs={breadcrumbs}
            tabs={tabs}
            onChangeTab={onChangeTab}
        >
            <div className={styles.savePage}>
                <Card>
                    <SaveForm
                        form={form}
                        formId={formId}
                        detailData={detailData}
                        isCreating={isCreating}
                        setDetailData={setDetailData}
                        onSubmit={isCreating ? onCreate : onUpdate}
                    />
                </Card>
                {
                    !disableActions
                    ?
                    <div className={styles.actionBar}>
                        {
                            actionButtons || (
                                <>
                                    <Button disabled={isSubmitting} onClick={onCancel}>Cancel</Button>
                                    <Button form={formId} type="primary" htmlType="submit" loading={isSubmitting}>Save</Button>
                                </>
                            )
                        }
                    </div>
                    :
                    null
                }
            </div>
        </PageWrapper>

    )
}

export default SavePageContainer;