import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import PageForm from './PageForm';

import paths from '@/constants/paths';
import { pageActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Pages', path: paths.pageList },
            { breadcrumbName: isCreating ? 'Create' : data?.title || '' }
        ];
    }
    return (
        <SavePageContainer
            form={PageForm}
            isCreating={!id}
            isUpdateRefresh
            objectName="page"
            listUrl={paths.pageList}
            detailUrl={paths.pageDetail}
            createAction={pageActions.create}
            updateAction={pageActions.update}
            getDetailAction={pageActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />
    )
}

export default SavePage;