import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'antd';

import { TextField, DropdownField } from '@/components/Form';
import { adminActions, adminActionTypes } from '@/redux/actions';
import { statusDDL } from '@/constants/masterData';

const AdminForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const dispatch = useDispatch();
    const ddlRoleLoading = useSelector(state => state.loading[adminActionTypes.GET_ROLE_LIST]);
    const roles = useSelector(state => state.admin.roles);

    const handleSubmit = (values) => {
        if(values.roles?.length) {
            values.roles = values.roles.filter(role => role.id);
        }
        onSubmit(values);
    }

    useEffect(() => {
        dispatch(adminActions.getRoleList());
    }, [dispatch])

    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={detailData}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="username"
                        label="Username"
                        required
                        disabled={!isCreating}
                        maxLength={30}
                    />
                </Col>
                <Col span={12}>
                    {
                        isCreating
                            ?
                            <TextField
                                fieldName="password"
                                type="password"
                                label="Password"
                                required
                            /> :
                            <TextField
                                fieldName="newPassword"
                                type="password"
                                label="New Password"
                            />
                    }
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="email"
                        label="E-mail"
                        required
                        validators={
                            [{
                                type: "email",
                                message: "The input is not valid E-mail!"
                            }]
                        }
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="fullName"
                        label="Full Name"
                        required
                    />
                </Col>
                <Col span={12}>
                    <DropdownField
                        fieldName={['roles', 0, 'id']}
                        label="Role"
                        placeholder="Select role"
                        options={roles}
                        optionValue="id"
                        optionLabel="name"
                        loading={ddlRoleLoading}
                    />
                </Col>
                <Col span={12}>
                    <DropdownField
                        fieldName="status"
                        label="Status"
                        required
                        options={statusDDL}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default AdminForm;