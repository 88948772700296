import { useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'antd';

import { TextField, NumericField, DropdownField, FullScreenLoading } from '@/components';

import { useNotification, useFetch } from '@/hooks';
import apiConfig from '@/constants/apiConfig';
import { attributeTypes } from '@/constants';


const formId = 'variant-form';

const SaveVariantModal = ({
    data,
    productId,
    onClose,
    getVariants
}) => {
    const [form] = Form.useForm();
    const isCreating = !data?.id;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const action = isCreating ? 'Create' : 'Update';

    const { showErrorMessage, showSucsessMessage } = useNotification();
    const { execute: saveVariant } = useFetch(isCreating ? apiConfig.variant.create : apiConfig.variant.update);
    const { loading: getAttributeLoading, data: attributes } = useFetch(
        {
            ...apiConfig.attribute.getByProductId,
            path: apiConfig.attribute.getByProductId.path.replace(':productId', productId)
        },
        {
            immediate: true,
            params: { type: attributeTypes.VARIANT },
            mappingData: response => response?.data || []
        }
    )

    const { loading: getVariantDetailLoading, data: variant } = useFetch(
        {
            ...apiConfig.variant.getDetail,
            path: apiConfig.variant.getDetail.path.replace(':id', data?.id)
        },
        {
            immediate: !isCreating,
            mappingData: response => {
                if(response?.data) {
                    const attributes = response.data.attributes.map(attr => ({[attr.attribute.id]: attr.values?.[0]?.slug}));
                    return {...response.data, attributes};
                }
                return null;
            }
        }
    )

    const onSubmit = (values) => {
        setIsSubmitting(true);
        saveVariant({
            params: {
                name: values.name,
                priceAmount: values.priceAmount,
                ...(isCreating ? {
                    productId,
                    sku: values.sku,
                    attributes: values.attributes.map(av => {
                        let attribute;
                        for (const [key, value] of Object.entries(av)) {
                            attribute = { id: key, value };
                        }
                        return attribute;
                    })
                } : {
                    id: data.id
                })
            },
            onCompleted: response => {
                setIsSubmitting(false);
                if (response?.result) {
                    showSucsessMessage(`${action} variant successful!`);
                    getVariants();
                    onClose();
                }
                else {
                    showErrorMessage(`${action} variant failed!`);
                }
            },
            onError: err => {
                console.log(err);
                setIsSubmitting(false);
                showErrorMessage(err.message || `${action} variant failed!`);
            }
        })
    }

    if (getAttributeLoading || getVariantDetailLoading) {
        return (
            <FullScreenLoading />
        )
    }

    return (
        <Modal
            maskClosable={false}
            bodyStyle={{ maxHeight: '80vh', minHeight: '200px' }}
            destroyOnClose // rerender child component when modal close
            style={{ top: 60 }}
            width={900}
            open={isCreating || variant}
            title={`${action} Variant`}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>Cancel</Button>,
                <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form={formId}
                    loading={isSubmitting}
                >
                    Save
                </Button>
            ]}
        >
            <Form
                id={formId}
                onFinish={onSubmit}
                form={form}
                layout="vertical"
                initialValues={variant || {}}
            >
                {
                    attributes?.length
                        ?
                        <Row gutter={16}>
                            {
                                attributes.map((attribute, index) => (
                                    <Col span={12} key={attribute.id}>
                                        <DropdownField
                                            fieldName={['attributes', index, attribute.id.toString()]}
                                            label={attribute.name}
                                            options={attribute.values || []}
                                            optionValue="slug"
                                            optionLabel="name"
                                            required
                                            disabled={!isCreating}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                        :
                        null
                }
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            fieldName="sku"
                            label="SKU"
                            required
                            disabled={!isCreating}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            fieldName="name"
                            label="Name"
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <NumericField
                            fieldName="priceAmount"
                            label="Price"
                            required
                            min={1}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default SaveVariantModal;
