import { useState, useEffect, useMemo, useRef } from 'react';
import { Button, Col, Row, Modal, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, DeleteOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';

import { BaseTable } from '@/components';
import AssignProductModal from './AssignProductModal';
import SaveAssignedProductModal from './SaveAssignedProductModal';

import { collectionActions } from '@/redux/actions';
import { useNotification, useFetch } from '@/hooks';
import apiConfig from '@/constants/apiConfig';
import { formatNumber } from '@/utils';
import { DEFAULT_TABLE_ITEM_SIZE } from '@/constants';

const { confirm } = Modal;

const AssignedProductList = ({
    detailData
}) => {
    const paginationRef = useRef({ pageSize: DEFAULT_TABLE_ITEM_SIZE, current: 1 });
    const dispatch = useDispatch();
    const assignedProductRef = useRef(null);

    const [isOpenSaveAssignedProductModal, setIsOpenSaveAssignedProductModal] = useState(false);
    const [isOpenAssignProductModal, setIsOpenAssignProductModal] = useState(false);

    const { data: products, loading, execute: getProducts } = useFetch(
        apiConfig.collection.getAssignedProduct,
        {
            mappingData: response => {
                const { content, totalElements } = response?.data || {};
                paginationRef.current.total = totalElements || 0;
                return content || [];
            }
        }
    );

    const { showErrorMessage, showSucsessMessage } = useNotification();

    const productColumns = useMemo(() => {
        return [
            { title: 'ID', dataIndex: 'id', render: (id, dataRow) => dataRow.product.id },
            {
                title: 'Image',
                dataIndex: 'image',
                render: (image, dataRow) => dataRow?.product?.image?.url ? <img src={dataRow?.product?.image?.url} style={{ heigth: 42, width: 42 }} alt={dataRow?.product?.image.alt} /> : <PictureOutlined className="empty-img-col" />
            },
            { title: 'Name', dataIndex: 'name', render: (name, dataRow) => dataRow?.product?.name },
            { title: 'Slug', dataIndex: 'slug', render: (name, dataRow) => dataRow?.product?.slug },
            { title: 'Order', dataIndex: 'sortOrder', render: sortOrder => sortOrder ? formatNumber(sortOrder) : null },
            {
                title: 'Action',
                width: '100px',
                align: 'center',
                render: dataRow =>
                (
                    <span className="action-col">
                        <Button type="link" onClick={() => onOpenSaveAssignProductModal(dataRow)}><EditOutlined/></Button>
                        <Divider type="vertical" />
                        <Button onClick={() => onConfirmUnassign(dataRow.id)} type="link"><DeleteOutlined /></Button>
                    </span>
                )
            }
        ]
    }, [detailData, products?.length]);


    const onUnassign = (id) => {
        dispatch(collectionActions.unassignProduct({
            params: { id },
            onCompleted: response => {
                if (response.result) {
                    const isLastItem = products?.length === 1;
                    if (paginationRef.current.current > 1 && isLastItem) {
                        paginationRef.current.current = paginationRef.current.current - 1;
                    }
                    getProductList();
                    showSucsessMessage('Unassign attribute successful!');
                }
                else {
                    showErrorMessage('Unassign attribute failed. Please try again!');
                }
            },
            onError: err => {
                console.log(err);
                showErrorMessage('Unassign attribute failed. Please try again!');
            }
        }))
    }

    const onConfirmUnassign = (id) => {
        confirm({
            title: `Are you sure unassign this product?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => onUnassign(id),
            onCancel: () => { }
        });
    }

    const onOpenSaveAssignProductModal = (data) => {
        assignedProductRef.current = data;
        setIsOpenSaveAssignedProductModal(true);
    }

    const getProductList = () => {
        const page = paginationRef.current.current - 1;
        getProducts({ params: { collectionId: detailData.id, page, size: DEFAULT_TABLE_ITEM_SIZE }});
    }

    const handleTableChange = (pagination, filters, sorter) => {
        paginationRef.current.current = pagination.current;
        getProductList();
    }


    useEffect(() => {
        getProductList();
    }, [detailData])

    return (
        <>
            <Row justify="end" style={{ marginBottom: '16px'}}>
                <Col>
                    <Button type="primary" onClick={() => setIsOpenAssignProductModal(true)}><PlusOutlined />Assign Product</Button>
                </Col>
            </Row>

            <BaseTable
                rowKey="id"
                columns={productColumns}
                dataSource={products}
                loading={loading}
                pagination={paginationRef.current}
                onChange={handleTableChange}
            />
            {
                isOpenAssignProductModal
                    ?
                    <AssignProductModal
                        isOpen={true}
                        data={detailData}
                        onClose={() => setIsOpenAssignProductModal(false)}
                        getList={getProductList}
                    />
                    :
                    null
            }
            {
                isOpenSaveAssignedProductModal
                    ?
                    <SaveAssignedProductModal
                        isOpen={true}
                        data={assignedProductRef.current}
                        onClose={() => setIsOpenSaveAssignedProductModal(false)}
                        getList={getProductList}
                    />
                    :
                    null
            }

        </>

    );
};

export default AssignedProductList;