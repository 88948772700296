import { ecomApiUrl } from '@/constants';

const baseHeader = {
    'Content-Type': 'application/json'
}

const multipartFormHeader = {
    'Content-Type': 'multipart/form-data'
}

const apiConfig = {
    account: {
        login: {
            path: `${ecomApiUrl}/auth/login`,
            method: 'POST',
            headers: baseHeader,
            isPublic: true,
        },
        getProfile: {
            path: `${ecomApiUrl}/account/profile`,
            method: 'GET',
            headers: baseHeader
        },
        refreshToken: {
            path: `${ecomApiUrl}/auth/refresh-token`,
            method: 'POST',
            headers: baseHeader,
            isPublic: true,
        },
    },
    admin: {
        getList: {
            path: `${ecomApiUrl}/admins`,
            method: 'GET',
            headers: baseHeader,
        },
        getRoleList: {
            path: `${ecomApiUrl}/roles`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/admins/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/admins`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/admins`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/admins/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    productType: {
        getList: {
            path: `${ecomApiUrl}/product-types`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/product-types/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/product-types`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/product-types`,
            method: 'PUT',
            headers: baseHeader
        },
        updateHasVariant: {
            path: `${ecomApiUrl}/product-types/update-has-variants`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/product-types/:id`,
            method: 'DELETE',
            headers: baseHeader
        },
        assignAttribute: {
            path: `${ecomApiUrl}/product-types/assign-attributes`,
            method: 'POST',
            headers: baseHeader
        },
        unassignAttribute: {
            path: `${ecomApiUrl}/product-types/unassign-attributes`,
            method: 'POST',
            headers: baseHeader
        },
        assignVariant: {
            path: `${ecomApiUrl}/product-types/assign-variants`,
            method: 'POST',
            headers: baseHeader
        },
        unassignVariant: {
            path: `${ecomApiUrl}/product-types/unassign-variants`,
            method: 'POST',
            headers: baseHeader
        },
        getAttributes: {
            path: `${ecomApiUrl}/product-types/:productTypeId/attributes`,
            method: 'GET',
            headers: baseHeader,
        },
        getVariants: {
            path: `${ecomApiUrl}/product-types/:productTypeId/variants`,
            method: 'GET',
            headers: baseHeader,
        },
    },
    attribute: {
        getList: {
            path: `${ecomApiUrl}/attributes`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/attributes/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        getByProductId: {
            path: `${ecomApiUrl}/product/:productId/attributes`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/attributes`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/attributes`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/attributes/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    attributeValue: {
        create: {
            path: `${ecomApiUrl}/attribute-values`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/attribute-values`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/attribute-values/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    category: {
        getList: {
            path: `${ecomApiUrl}/product-category`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/product-category/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/product-category`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/product-category`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/product-category/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    collection: {
        getList: {
            path: `${ecomApiUrl}/collections`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/collections/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/collections`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/collections`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/collections/:id`,
            method: 'DELETE',
            headers: baseHeader
        },
        getAssignedProduct: {
            path: `${ecomApiUrl}/collection-products`,
            method: 'GET',
            headers: baseHeader
        },
        assignProduct: {
            path: `${ecomApiUrl}/collection-products`,
            method: 'POST',
            headers: baseHeader
        },
        unassignProduct: {
            path: `${ecomApiUrl}/collection-products/:id`,
            method: 'DELETE',
            headers: baseHeader
        },
        updateAssignedProduct: {
            path: `${ecomApiUrl}/collection-products`,
            method: 'PUT',
            headers: baseHeader
        },
    },
    product: {
        getList: {
            path: `${ecomApiUrl}/product`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/product/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/product`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/product`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/product/:id`,
            method: 'DELETE',
            headers: baseHeader
        },
        updateAttribute: {
            path: `${ecomApiUrl}/product/update-attribute`,
            method: 'PUT',
            headers: baseHeader
        },
        uploadImage: {
            path: `${ecomApiUrl}/product-image`,
            method: 'POST',
            headers: multipartFormHeader
        },
        deleteImage: {
            path: `${ecomApiUrl}/product-image/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    media: {
        image: {
            path: `${ecomApiUrl}/media/upload-image`,
            method: 'POST',
            headers: multipartFormHeader
        }
    },
    variant: {
        getList: {
            path: `${ecomApiUrl}/product-variant`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/product-variant/:id`,
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: `${ecomApiUrl}/product-variant`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/product-variant`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/product-variant/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
    order: {
        getList: {
            path: `${ecomApiUrl}/orders`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/orders/:id`,
            method: 'GET',
            headers: baseHeader
        },
        updateOrderStatus: {
            path: `${ecomApiUrl}/orders/update-status`,
            method: 'PUT',
            headers: baseHeader
        },
    },
    page: {
        getList: {
            path: `${ecomApiUrl}/pages`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${ecomApiUrl}/pages/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${ecomApiUrl}/pages`,
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: `${ecomApiUrl}/pages`,
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: `${ecomApiUrl}/pages/:id`,
            method: 'DELETE',
            headers: baseHeader
        }
    },
}

export default apiConfig;