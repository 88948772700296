import React from 'react';
import { Table } from 'antd';
import classNames from 'classnames';

import styles from './BaseTable.module.scss';

const BaseTable = ({ className, ...props }) => (
    <Table
        className={classNames(styles.baseTable, { [className]: !!className })}
        size="middle"
        // scroll={{ x: true }}
        // scroll={{ x: 'max-content' }}
        {...props}
    />
)

export default BaseTable;