import { Form, Row, Col } from 'antd';

import { TextField, BooleanField, FieldSet } from '@/components';
import UploadImageField from '@/components/Form/UploadImageField';

const CollectionForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    return (
        <Form
            id={formId}
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            initialValues={isCreating ? { valueRequired: false, visibleInStorefront: false, filterableInStorefront: false } : detailData}
        >
            <FieldSet title="General Infomation">
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            fieldName="name"
                            label="Name"
                            required
                        />
                    </Col>
                    {!isCreating && (
                        <Col span={12}>
                            <TextField
                                fieldName="slug"
                                label="Slug"
                                required
                            />
                        </Col>
                    )}
                    <Col span={12}>
                        <BooleanField
                            fieldName="isPublished"
                            label="Published"
                        />
                    </Col>
                    {!isCreating && (
                        <>
                            <Col span={12}>
                                <UploadImageField
                                    fieldName="image"
                                    label="Image"
                                    maxCount={1}
                                />
                            </Col>
                            <Col span={12}>
                                <TextField
                                    fieldName="imageAlt"
                                    label="Image Alt"
                                />
                            </Col>
                        </>
                    )}
                    <Col span={24}>
                        <TextField
                            fieldName="description"
                            label="Description"
                            type="textarea"
                        />
                    </Col>
                </Row>
            </FieldSet>
            <FieldSet title="SEO">
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField
                            fieldName="seoTitle"
                            label="SEO Title"
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            fieldName="seoDescription"
                            label="SEO description"
                        />
                    </Col>
                </Row>
            </FieldSet>
        </Form>
    );
};

export default CollectionForm;