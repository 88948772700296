import { takeLatest } from 'redux-saga/effects';

import { variantActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    DELETE,
} = variantActionTypes;


const deleteVariant = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.variant.delete,
        path: apiConfig.variant.delete.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const variantSagas = [
    takeLatest(DELETE, deleteVariant),
];

export default variantSagas;