import { takeLatest } from 'redux-saga/effects';

import { collectionActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    GET_LIST,
    GET_DETAIL,
    CREATE,
    UPDATE,
    DELETE,
    ASSIGN_PRODUCT,
    UNASSIGN_PRODUCT,
    UPDATE_ASSIGNED_PRODUCT
} = collectionActionTypes;

const getList = (action) => {
    return processCallbackAction(apiConfig.collection.getList, action);
}

const getDetail = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.collection.getDetail,
        path: apiConfig.collection.getDetail.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const create = (action) => {
    return processCallbackAction(apiConfig.collection.create, action);
}

const update = (action) => {
    return processCallbackAction(apiConfig.collection.update, action);
}

const deleteCollection = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.collection.delete,
        path: apiConfig.collection.delete.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const assignProduct = (action) => {
    return processCallbackAction(apiConfig.collection.assignProduct, action);
}

const unassignProduct = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.collection.unassignProduct,
        path: apiConfig.collection.unassignProduct.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const updateAssignedProduct = (action) => {
    return processCallbackAction(apiConfig.collection.updateAssignedProduct, action);
}

const collectionSagas = [
    takeLatest(GET_LIST, getList),
    takeLatest(CREATE, create),
    takeLatest(GET_DETAIL, getDetail),
    takeLatest(UPDATE, update),
    takeLatest(DELETE, deleteCollection),
    takeLatest(ASSIGN_PRODUCT, assignProduct),
    takeLatest(UNASSIGN_PRODUCT, unassignProduct),
    takeLatest(UPDATE_ASSIGNED_PRODUCT, updateAssignedProduct)
];

export default collectionSagas;