import { takeLatest } from 'redux-saga/effects';

import { orderActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    GET_LIST,
    GET_DETAIL,
    UPDATE_ORDER_STATUS
} = orderActionTypes;

const getList = (action) => {
    return processCallbackAction(apiConfig.order.getList, action);
}

const getDetail = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.order.getDetail,
        path: apiConfig.order.getDetail.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const updateOrderStatus = (action) => {
    return processCallbackAction(apiConfig.order.updateOrderStatus, action);
}


const orderSagas = [
    takeLatest(GET_LIST, getList),
    takeLatest(GET_DETAIL, getDetail),
    takeLatest(UPDATE_ORDER_STATUS, updateOrderStatus),
];

export default orderSagas;