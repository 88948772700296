import { Form, Row, Col } from 'antd';

import { TextField, TextEditorField } from '@/components';

const PageForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {

    return (
        <Form
            id={formId}
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            initialValues={detailData}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="title"
                        label="Title"
                        required
                    />
                </Col>
                {!isCreating && (
                    <Col span={12}>
                        <TextField
                            fieldName="slug"
                            label="Slug"
                            required
                        />
                    </Col>
                )}
                <Col span={24}>
                    <TextField
                        fieldName="description"
                        label="Description"
                        type="textarea"
                    />
                </Col>
                <Col span={24}>
                    <TextEditorField fieldName="content" label="Content"/>
                </Col>
            </Row>
        </Form>
    );
};

export default PageForm;