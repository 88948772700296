import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import CollectionForm from './CollectionForm';

import paths from '@/constants/paths';
import { collectionActions } from '@/redux/actions';
import AssignedProductList from './AssignedProductList';

const GENERAL_TAB = 'general-tab';
const PRODUCT_TAB = 'product-tab';

const SavePage = () => {
    const [activeTab, setActiveTab] = useState(GENERAL_TAB);
    const { id } = useParams();
    const isCreating = !id;
    
    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Collections', path: paths.collectionList },
            { breadcrumbName: isCreating ? 'Create' : data?.name || '' }
        ];
    }

    const onChangeTab = (tab) => {
        setActiveTab(tab);
    }
    const isGeneralTab = activeTab === GENERAL_TAB;

    return (
        <SavePageContainer
            form={isGeneralTab ? CollectionForm : AssignedProductList}
            tabs={[
                {
                    key: GENERAL_TAB,
                    label: 'General'
                },
                (!isCreating && {
                    key: PRODUCT_TAB,
                    label: 'Products'
                })
            ]}
            isCreating={!id}
            disableActions={!isGeneralTab}
            objectName="collection"
            listUrl={paths.collectionList}
            detailUrl={paths.collectionDetail}
            createAction={collectionActions.create}
            updateAction={collectionActions.update}
            getDetailAction={collectionActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            onChangeTab={onChangeTab}
        />
    )
}

export default SavePage;