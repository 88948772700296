import { Badge } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { ListPageContainer } from '@/components';
import { productActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            objectName="Product"
            detailUrl ={paths.productDetail}
            createUrl={paths.productCreate}
            breadcrumbs={[
                { breadcrumbName: 'Product' }
            ]}
            columns={[
                { title: 'ID', dataIndex: 'id' },
                {
                    title: 'Image',
                    dataIndex: 'image',
                    render: image => image?.url ? <img src={image?.url} style={{ heigth: 42, width: 42 }} alt={image.alt} /> : <PictureOutlined className="empty-img-col" />
                },
                { title: 'Name', dataIndex: 'name' },
                { title: 'Product Type', dataIndex: 'productType', render: productType => productType?.name },
                { title: 'Category', dataIndex: 'category', render: category => category?.name },
                { title: 'Published', dataIndex: 'isPublished', render: isPublished => (
                    <><Badge status={isPublished ? "success" : "warning"} /> {isPublished ? 'Published' : 'Not Published'}</>
                )}
            ]}
            searchFields={[
                { key: 'name', searchPlaceholder: 'Name' }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: false
            }}
            getListAction={productActions.getList}
            deleteAction={productActions.delete}
        />

    )
}

export default ListPage;