import { useEffect, useMemo } from 'react';
import { Form, Row, Col } from 'antd';

import { DropdownField, TextField, FullScreenLoading } from '@/components';

import { useFetch } from '@/hooks';
import apiConfig from '@/constants/apiConfig';
import { productAttributeInputTypes, attributeTypes } from '@/constants';

const metadatas = {
    [productAttributeInputTypes.DROPDOWN]: {
        valueKey: 'valueIds',
        getValue: (p) => p.values?.[0]?.id,
        setValue: (v) => v ? [v] : []
    },
    [productAttributeInputTypes.PLAIN_TEXT]: {
        valueKey: 'value',
        getValue: (p) => p.value,
        setValue: (v) => v
    }
}

const AttributeForm = ({
    form,
    formId,
    detailData,
    onSubmit
}) => {
    const { data, loading: isAttributeLoading, execute: getAttributes } = useFetch({
        ...apiConfig.attribute.getByProductId,
        path: `${apiConfig.attribute.getByProductId.path.replace(':productId', detailData.id)}?type=${attributeTypes.ATTRIBUTE}`
    });

    const attributes = useMemo(() => {
        return data?.data || [];
    }, [data?.data])

    const productAttributeObj = useMemo(() => {
        const obj = {};
        if(detailData?.attributes?.length) {
            detailData.attributes.forEach(pAttribute => {
                const metadata = metadatas[pAttribute.attribute.inputType];
                obj[pAttribute.attribute.id] = metadata.getValue(pAttribute)
            })
        }
        return obj;
    }, [detailData?.attributes])

    const renderFieldItem = (attribute) => {
        const { id, inputType, name, valueRequired, values } = attribute || {};
        switch (inputType) {
            case productAttributeInputTypes.DROPDOWN:
                return (
                    <DropdownField
                        fieldName={id}
                        label={name}
                        options={values || []}
                        optionValue="id"
                        optionLabel="name"
                        required={valueRequired}
                    />
                )
            case productAttributeInputTypes.PLAIN_TEXT:
                return (
                    <TextField
                        fieldName={id}
                        label={name}
                        required={valueRequired}
                    />
                )
            default:
                return null;
        }
    }

    const handleSubmit = values => {
        const data = Object.keys(values).map(attributeId => {
            const attribute = attributes.find(attr => attr.id === parseInt(attributeId));
            const metadata = metadatas[attribute.inputType];
            return { id: attributeId, [metadata.valueKey]: metadata.setValue(values[attributeId]) }
        });

        if(data?.length) {
            onSubmit({
                id: detailData.id,
                attributes: data
            });
        }
    }

    useEffect(() => {
        if (detailData.id) {
            getAttributes();
        }
    }, [detailData])

    if(isAttributeLoading) {
        return (<FullScreenLoading/>)
    }

    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={productAttributeObj}
        >
            <Row gutter={16}>
                {attributes.map(attr => (
                    <Col span={12} key={attr.id}>
                        {renderFieldItem(attr)}
                    </Col>
                ))}
            </Row>
        </Form>
    )
}

export default AttributeForm;