import dayjs from "dayjs"
import { DATETIME_FORMAT_DISPLAY } from "@/constants";

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);


export const convertUtcToLocalTimeString = (utcTime, format = DATETIME_FORMAT_DISPLAY) => {
    try {
        if (utcTime)
            return dayjs.utc(utcTime).local().format(format);
        return '';
    } catch (err) {
        return '';
    }
}