import { takeLatest } from 'redux-saga/effects';

import { productTypeActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    GET_LIST,
    GET_DETAIL,
    CREATE,
    UPDATE,
    DELETE,
    ASSIGN_ATTRIBUTE,
    UPDATE_HAS_VARIANT,
    UNASSIGN_ATTRIBUTE,
    ASSIGN_VARIANT,
    UNASSIGN_VARIANT
} = productTypeActionTypes;

const getList = (action) => {
    return processCallbackAction(apiConfig.productType.getList, action);
}

const getDetail = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.productType.getDetail,
        path: apiConfig.productType.getDetail.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const create = (action) => {
    return processCallbackAction(apiConfig.productType.create, action);
}

const update = (action) => {
    return processCallbackAction(apiConfig.productType.update, action);
}

const updateHasVariant = (action) => {
    return processCallbackAction(apiConfig.productType.updateHasVariant, action);
}

const deleteProductType = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.productType.delete,
        path: apiConfig.productType.delete.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError }});
}

const assignAttribute = (action) => {
    return processCallbackAction(apiConfig.productType.assignAttribute, action);
}

const unassignAttribute = (action) => {
    return processCallbackAction(apiConfig.productType.unassignAttribute, action);
}

const assignVariant = (action) => {
    return processCallbackAction(apiConfig.productType.assignVariant, action);
}

const unassignVariant = (action) => {
    return processCallbackAction(apiConfig.productType.unassignVariant, action);
}

const productTypeSagas = [
    takeLatest(GET_LIST, getList),
    takeLatest(CREATE, create),
    takeLatest(GET_DETAIL, getDetail),
    takeLatest(UPDATE, update),
    takeLatest(DELETE, deleteProductType),
    takeLatest(UPDATE_HAS_VARIANT, updateHasVariant),
    takeLatest(ASSIGN_ATTRIBUTE, assignAttribute),
    takeLatest(UNASSIGN_ATTRIBUTE, unassignAttribute),
    takeLatest(ASSIGN_VARIANT, assignVariant),
    takeLatest(UNASSIGN_VARIANT, unassignVariant),
];

export default productTypeSagas;