import { all } from 'redux-saga/effects';

import account from './account';
import admin from './admin';
import productType from './productType';
import attribute from './attribute';
import attributeValue from './attributeValue';
import category from './category';
import collection from './collection';
import product from './product';
import variant from './variant';
import order from './order';
import page from './page';

const sagas = [
    ...account,
    ...admin,
    ...productType,
    ...attribute,
    ...category,
    ...collection,
    ...attributeValue,
    ...product,
    ...variant,
    ...order,
    ...page,
];

function* rootSaga() {
    yield all(sagas);
}

export default rootSaga;