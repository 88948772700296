import { useState } from 'react';
import { Select } from 'antd';

import FormItem from './FormItem';

import { fieldTypes } from '@/constants';


const DropdownField = ({
    loading,
    disabled,
    mode,
    options,
    optionValue,
    optionLabel,
    optionOther,
    onChange,
    onSelect,
    defaultValue,
    allowClear,
    showSearch,
    ...formItemProps
}) => {
    const [search, setSearch] = useState();

    const optionValueKey = optionValue || 'value';
    const optionLabelKey = optionLabel || 'label';
    const optionOtherKey = optionOther || 'other';

    const DropdownInput = ({ getPlaceHolder, ...inputProps }) => {
        return (
            <Select
                allowClear={allowClear ?? !loading}
                showArrow
                loading={loading}
                placeholder={getPlaceHolder()}
                mode={mode}
                disabled={disabled}
                onSelect={onSelect}
                onChange={onChange}
                defaultValue={defaultValue}
                getPopupContainer={(trigger) => trigger.parentElement}
                {...(showSearch ? {
                    showSearch,
                    searchValue: search,
                    onSearch: search => setSearch(search),
                } : {})}
                {...inputProps}
            >
                {
                    options
                        ?
                        options.map(item =>
                            <Select.Option
                                key={item[optionValueKey]}
                                value={item[optionValueKey]}
                                label={item.label}
                                other={item[optionOtherKey]}
                                disabled={item.disabled}
                            >
                                {item[optionLabelKey]}
                            </Select.Option>

                        )
                        :
                        null
                }
            </Select>
        )
    }

    return (
        <FormItem
            fieldType={fieldTypes.SELECT}
            {...formItemProps}
        >
            <DropdownInput/>
        </FormItem>
    )
}

export default DropdownField;
